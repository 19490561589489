import React, { useContext } from "react";

import { BrowserRouter as Router, Switch, Route } from "react-router-dom";

import Home from "./components/Home";
import Alert from "./components/Alert";

function Routes() {
  return (
    <Router>
      <div className="fixed right-0 top-0 mx-4 my-24 z-50">
        <Alert />
      </div>
      <Switch>
        <Route exact path="/">
          <Home puedeEditar={true} />
        </Route>
        <Route exact path="/view-only">
          <Home puedeEditar={false} />
        </Route>
      </Switch>
    </Router>
  );
}

export default Routes;
