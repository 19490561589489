import React, { Fragment, useState, useContext, useEffect } from "react";
import AlertContext from "../context/alerts/alertContext";
import AuthContext from "../context/auth/authContext";
import DientesContext from "../context/dientes/dientesContext";
import { useHistory } from "react-router-dom";
import { useParams } from "react-router-dom";
import LoadingScreen from "./LoadingScreen";
import BaseTemplate from "./BaseTemplate";
import HomeOdontograma from "./HomeOdontograma";

function Home({ puedeEditar }) {
  const alertContext = useContext(AlertContext);
  const { setAlert } = alertContext;

  const authContext = useContext(AuthContext);
  const {
    getUser,
    canEdit,
    cambiarCanEdit,
    buscarOdontograma,
    loadingUsuario,
    loadingDatosOdontograma,
    datosOdon,
  } = authContext;

  const dientesContext = useContext(DientesContext);
  const {
    obtenerTrabajosOdontograma,
    obtenerListaTrabajos,
    guardarOdontograma,
    piezasEnBoca,
    trabajosParaGuardar,
    trabajosAgregados,
  } = dientesContext;

  // console.log(trabajosAgregados)
  // console.log(trabajosParaGuardar);

  let history = useHistory();

  //obtener parametros de la URL
  let search = window.location.search;
  let params = new URLSearchParams(search);

  //obtener parametros de usuario
  let apikey = params.get("apikey") ? params.get("apikey") : null;
  let odonID = params.get("odonID") ? params.get("odonID") : null;

  const data = {
    apikey,
    odonID,
  };

  //valida que los objetos del data no estén vacios o con datos vacios
  function validarURL(obj) {
    let arr = Object.values(obj);
    let validacion = true;
    arr.map((i) => {
      if (i === null || i === undefined || i === "") {
        validacion = false;
      }
    });
    return validacion;
  }

  useEffect(() => {
    getUser(apikey);
    cambiarCanEdit(puedeEditar);
    buscarOdontograma(data);
    obtenerListaTrabajos(apikey, datosOdon);
    obtenerTrabajosOdontograma(apikey, datosOdon);
  }, []);

  useEffect(() => {
    obtenerListaTrabajos(apikey, datosOdon);
    obtenerTrabajosOdontograma(apikey, datosOdon);
  }, [datosOdon]);

  const {
    paciente_apyn,
    nro_afiliado,
    paciente_dni,
    plan_afiliado,
    paciente_fecha_nac,
    paciente_edad,
  } = datosOdon;

  if (loadingUsuario || !validarURL(data) || loadingDatosOdontograma) {
    return (
      <LoadingScreen
        loadingUsuario={loadingUsuario}
        loadingDatosOdontograma={loadingDatosOdontograma}
        validacionUrl={validarURL(data)}
      />
    );
  } else {
    return (
      <BaseTemplate>
        <div className="bg-white rounded-lg shadow-lg p-4 mb-4">
          <div className="mb-5">
            <div className="xl:flex xl:items-center xl:justify-between mb-3">
              <h2 className="text-lg xl:text-xl font-bold text-blue-700">
                {paciente_apyn}
              </h2>
              <div className="bg-blue-700 px-5 py-1 rounded-md flex items-center text-white">
                <svg className="h-4 w-4 mr-3" viewBox="0 0 448 512">
                  <path
                    d="M443.98 96.25c-11.01-45.22-47.11-82.06-92.01-93.72c-32.19-8.36-63 5.1-89.14 24.33c-3.25 2.39-6.96 3.73-10.5 5.48l28.32 18.21c7.42 4.77 9.58 14.67 4.8 22.11c-4.46 6.95-14.27 9.86-22.11 4.8L162.83 12.84c-20.7-10.85-43.38-16.4-66.81-10.31c-44.9 11.67-81 48.5-92.01 93.72c-10.13 41.62-.42 80.81 21.5 110.43c23.36 31.57 32.68 68.66 36.29 107.35c4.4 47.16 10.33 94.16 20.94 140.32l7.8 33.95c3.19 13.87 15.49 23.7 29.67 23.7c13.97 0 26.15-9.55 29.54-23.16l34.47-138.42c4.56-18.32 20.96-31.16 39.76-31.16s35.2 12.85 39.76 31.16l34.47 138.42c3.39 13.61 15.57 23.16 29.54 23.16c14.18 0 26.48-9.83 29.67-23.7l7.8-33.95c10.61-46.15 16.53-93.16 20.94-140.32c3.61-38.7 12.93-75.78 36.29-107.35c21.95-29.61 31.66-68.8 21.53-110.43z"
                    fill="currentColor"
                  ></path>
                </svg>
                <p className="font-light">
                  Piezas en Boca:{" "}
                  <span className="font-bold">{piezasEnBoca}</span>
                </p>
              </div>
            </div>
            <div className="flex items-center p-4 bg-gray-100 rounded-md">
              <div className="mr-3">
                <p className="font-ligt text-gray-600 text-sm">
                  <b>DNI:</b> {paciente_dni}
                </p>
                <p className="font-ligt text-gray-600 text-sm">
                  <b>Fecha de Nacimiento:</b> {paciente_fecha_nac} (Edad{" "}
                  {paciente_edad})
                </p>
              </div>
              <div>
                <p className="font-ligt text-gray-600 text-sm">
                  <b>Afiliado Nro:</b> {nro_afiliado}
                </p>
                <p className="font-ligt text-gray-600 text-sm">
                  <b>Plan:</b> {plan_afiliado}
                </p>
              </div>
            </div>
          </div>
          <HomeOdontograma />
          <div className="bg-blue-100 rounded-md mt-10 mb-4 block xl:hidden p-4">
            <div className="flex items-center">
              <div className="h-8 w-8 rounded-full flex items-center justify-center bg-blue-700 mr-4">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  className="h-6 w-6 text-white"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                    d="M7 11.5V14m0-2.5v-6a1.5 1.5 0 113 0m-3 6a1.5 1.5 0 00-3 0v2a7.5 7.5 0 0015 0v-5a1.5 1.5 0 00-3 0m-6-3V11m0-5.5v-1a1.5 1.5 0 013 0v1m0 0V11m0-5.5a1.5 1.5 0 013 0v3m0 0V11"
                  />
                </svg>
              </div>
              <div>
                <p className="text-sm font-light text-blue-600">
                  Si hay piezas que no podes ver
                </p>
                <p className="font-medium text-sm text-blue-600">
                  Navegá arrastrando hacia los costados
                </p>
              </div>
            </div>
          </div>
          <div className="bg-gray-100 p-4 rounded-lg flex items-center justify-end">
            <button
              onClick={() => guardarOdontograma(data)}
              type="button"
              disabled={trabajosParaGuardar.length === 0}
              className={`w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 text-base font-bold text-white focus:outline-none sm:ml-3 sm:w-auto sm:text-sm ${
                !canEdit && "hidden"
              } ${
                trabajosParaGuardar.length === 0
                  ? "bg-gray-300"
                  : "bg-green-500"
              } ${trabajosParaGuardar.length > 0 && "hover:bg-green-400"} ${
                trabajosParaGuardar.length === 0
                  ? "cursor-not-allowed"
                  : "cursor-pointer"
              }`}
              // className={
              //   canEdit
              //     ? "w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-green-500 text-base font-bold text-white hover:bg-green-400 focus:outline-none sm:ml-3 sm:w-auto sm:text-sm"
              //     : "w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-green-500 text-base font-bold text-white hover:bg-green-400 focus:outline-none sm:ml-3 sm:w-auto sm:text-sm hidden"
              // }
            >
              Guardar Odontograma
            </button>
          </div>
        </div>
      </BaseTemplate>
    );
  }
}

export default Home;
