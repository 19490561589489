import React from "react";
import ReactDOM from "react-dom";
import App from "./App";
import "./assets/css/styles.css";

import DientesState from "./context/dientes/dientesState";
import AlertState from "./context/alerts/alertState";
import AuthState from "./context/auth/authState";

ReactDOM.render(
  <React.StrictMode>
    <AlertState>
      <AuthState>
        <DientesState>
          <App />
        </DientesState>
      </AuthState>
    </AlertState>
  </React.StrictMode>,
  document.getElementById("root")
);
