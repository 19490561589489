import React, { useContext } from "react";
import DientesContext from "../../context/dientes/dientesContext";
import TrabajoIconos from "./TrabajosIconos";
import AuthContext from "../../context/auth/authContext";

function ExteriorPieza({ cara, diente, piezaSelector }) {
  const dientesContext = useContext(DientesContext);
  const { getSeleccionadoData, getCaraData, setModalOpen } = dientesContext;
  const authContext = useContext(AuthContext);
  const { canEdit } = authContext;
 
  const dataCara = { ...cara, idDiente: diente.id };
  
  
  const handleClick = () => {
    getSeleccionadoData(diente);
    getCaraData(dataCara);
    setModalOpen(canEdit);
  };

  let color = "#ffffff";

  if (cara.trabajo) {
    color = cara.trabajo.color;
  }

  if (cara.posicion === "arriba-pieza" && cara.trabajo !== "") {
    return (
      <div className="relative">
        <div className="h-3 w-full" onClick={handleClick}>
          <TrabajoIconos
            nombre={cara.trabajo.nombre}
            color={color}
            estado={cara.trabajo.estado}
          />
        </div>
      </div>
    );
  } else {
    return (
      <div className="relative">
        {piezaSelector ? (
          <div
            className="h-4 w-full bg-gray-100 border border-gray-400"
            onClick={handleClick}
          ></div>
        ) : (
          <div></div>
        )}
      </div>
    );
  }
}

export default ExteriorPieza;
