import React from "react";

import Draggable, { DraggableCore } from "react-draggable";
import Pieza from "./Pieza";

function Builder({ pacienteData, piezaSelector }) {
  return (
    <div className="flex items-start">
      {pacienteData.length > 0
        ? pacienteData.map((d, idex) => (
            <Pieza
              key={idex}
              posiciones={d.posiciones}
              data={d}
              piezaSelector={piezaSelector}
            />
          ))
        : null}
    </div>
  );
}

export default Builder;
