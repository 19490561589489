import React, { useContext, useEffect, useState } from "react";
import DientesContext from "../../context/dientes/dientesContext";

import Trabajo from "./Trabajo";

function TrabajosSelect() {
  const dientesContext = useContext(DientesContext);
  const {
    caraSeleccionada,
    getCaraData,
    getTrabajosData,
    trabajosPorSector,
    trabajosSector,
    estadoSeleccionado,
    cambiarEstadoSeleccionado,
    mutarDiente,
    trabajosParaGuardar,
    setTrabajosParaGuardar,
    trabajosAgregados,
    setTrabajosAgregados,
  } = dientesContext;

  useEffect(() => {
    getCaraData({});
  }, []);

  useEffect(() => {
    trabajosPorSector(caraSeleccionada.alcance, estadoSeleccionado);
  }, [caraSeleccionada, estadoSeleccionado]);

  const handleChange = (e) => {
    cambiarEstadoSeleccionado(e.target.value);
  };

  // console.log(trabajosSector);

  const vaciarCara = () => {
    mutarDiente("");

    const dienteConTrabajoABorrar = trabajosParaGuardar.filter(
      (trabajo) => trabajo.pieza_numero === caraSeleccionada.idDiente
    );

    const trabajoABorrar = dienteConTrabajoABorrar.find(
      (d) => d.posicion === caraSeleccionada.posicion
    );

    const trabajosFiltrados = trabajosParaGuardar.filter(
      (el) => el !== trabajoABorrar
    );

    setTrabajosParaGuardar(trabajosFiltrados);
  };

  if (caraSeleccionada.alcance === "cara") {
    return (
      <div className="bg-gray-100 rounded-md p-4 mt-8">
        <select
          onChange={handleChange}
          className="w-full px-3 py-2 mb-4 rounded-md border-2 border-gray-500"
        >
          <option value="arealizar">A Realizar</option>
          <option value="realizado">Realizado</option>
        </select>

        <div className="mb-2 flex items-center mr-2" onClick={vaciarCara}>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 198.02 198.02"
            fill="#ffffff"
            className="h-6 w-6"
          >
            <rect
              stroke="gray"
              strokeWidth="20"
              x="0.5"
              y="0.5"
              width="197.02"
              height="197.02"
            />
          </svg>
          <p className="text-xs ml-2">Borrar</p>
        </div>

        {trabajosSector.length > 0
          ? trabajosSector.map((t) => (
              <Trabajo
                key={t.id}
                nombre={t.nombre}
                color={t.color}
                nombrePublico={t.nombrePublico}
                colorSecundario={t.colorSecundario}
                data={t}
              />
            ))
          : null}
      </div>
    );
  }

  if (
    caraSeleccionada.alcance === "pieza" ||
    caraSeleccionada.alcance === "exterior-pieza"
  ) {
    return (
      <div className="bg-gray-100 rounded-md p-4 mt-8">
        <select
          onChange={handleChange}
          className="w-full px-3 py-2 mb-4 rounded-md border-2 border-gray-500"
        >
          <option value="arealizar">A Realizar</option>
          <option value="realizado">Realizado</option>
        </select>
        <p className="text-lg mb-4">
          <span className="font-bold">Trabajos Disponibles</span>{" "}
          {caraSeleccionada.alcance}
        </p>
        <div className="mb-2 flex items-center mr-2" onClick={vaciarCara}>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 198.02 198.02"
            fill="#ffffff"
            className="h-6 w-6"
          >
            <rect
              stroke="gray"
              strokeWidth="20"
              x="0.5"
              y="0.5"
              width="197.02"
              height="197.02"
            />
          </svg>
          <p className="text-xs ml-2">Borrar</p>
        </div>
        {trabajosSector.length > 0
          ? trabajosSector.map((t) => (
              <Trabajo
                key={t.id}
                nombre={t.nombre}
                nombrePublico={t.nombrePublico}
                color={t.color}
                colorSecundario={t.colorSecundario}
                data={t}
              />
            ))
          : null}
      </div>
    );
  }
  return (
    <div className="bg-gray-100 rounded-md p-4 mt-8">
      <p className="text-gray-500 font-medium">Seleccionar una cara...</p>
    </div>
  );
}

export default TrabajosSelect;
