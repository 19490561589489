import React, { useContext, useEffect, useState } from "react";
import DientesContext from "../../context/dientes/dientesContext";

import TrabajosIconos from "../builder/TrabajosIconos";

function Trabajo({ nombre, color, colorSecundario, data, nombrePublico }) {
  const dientesContext = useContext(DientesContext);
  const { mutarDiente } = dientesContext;

  const handleClick = (data) => {
    mutarDiente(data);
  };

  return (
    <div className="h-6 w-full mb-2" onClick={() => handleClick(data)}>
      <div className="relative flex items-center">
        <div className="flex items-center justify-center mr-2">
          {data.alcance === "cara" ? (
            <div className="flex items-center justify-center">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 198.02 198.02"
                fill={color}
                className="h-6 w-6"
              >
                <rect
                  stroke="gray"
                  strokeWidth="20"
                  x="0.5"
                  y="0.5"
                  width="197.02"
                  height="197.02"
                />
              </svg>
              {nombre === "recidiva" ? (
                <svg
                  viewBox="0 0 120 120"
                  fill={colorSecundario}
                  className="h-2 w-2 absolute"
                >
                  <circle cx="60" cy="60" r="50" />
                </svg>
              ) : null}
            </div>
          ) : null}

          {data.alcance === "pieza" || data.alcance === "exterior-pieza" ? (
            <div>
              <TrabajosIconos
                nombre={nombre}
                color={color}
                estado={data.estado}
              />
            </div>
          ) : null}
        </div>
        <p className="text-xs">{nombrePublico}</p>
      </div>
    </div>
  );
}

export default Trabajo;
