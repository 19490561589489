import { SET_ALERT, DELETE_ALERT } from "../../types/index.js";

export default (state, action) => {
  switch (action.type) {
    case SET_ALERT:
      return {
        alert: action.payload
      };
    case DELETE_ALERT:
      return {
        alert: null
      };

    default:
      return state;
  }
};
