import {
  GET_PACIENTE_DATA,
  GET_PACIENTE_TEMPORALES_DATA,
  DIENTE_SELECCIONADO,
  CARA_SELECCIONADA,
  GET_TRABAJOS,
  FILTER_TRABAJOS,
  CAMBIAR_ESTADO,
  
} from "../../types/index.js";

export default (state, action) => {
  switch (action.type) {
    case GET_PACIENTE_DATA:
      return {
        ...state,
        pacienteData: action.payload,
      };
      case GET_PACIENTE_TEMPORALES_DATA:
        return {
          ...state,
          pacienteTemporalesData: action.payload,
        };
    case DIENTE_SELECCIONADO:
      return {
        ...state,
        dienteSeleccionado: action.payload,
      };
    case CARA_SELECCIONADA:
      return {
        ...state,
        caraSeleccionada: action.payload,
      };
    case GET_TRABAJOS:
      return {
        ...state,
        trabajos: action.payload,
      };
    case FILTER_TRABAJOS:
      return {
        ...state,
        trabajosSector: action.payload,
      };
    case CAMBIAR_ESTADO:
      return {
        ...state,
        estadoSeleccionado: action.payload,
      };

    default:
      return state;
  }
};
