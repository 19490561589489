import React from "react";
import Caras from "./Caras";
import PiezaCompleta from "./PiezaCompleta";
import ExteriorPieza from "./ExteriorPieza";

function Pieza({ posiciones, data, piezaSelector }) {
  return (
    <div className="h-10 w-10 mr-2">
      <div className="relative h-10 w-10 mb-1">
        {posiciones
          ? posiciones.map((p, index) => {
              switch (p.posicion) {
                case "arriba-pieza":
                  return (
                    <div key={index} className="absolute -top-6 w-full z-20">
                      <ExteriorPieza
                        cara={p}
                        diente={data}
                        piezaSelector={piezaSelector}
                      />
                    </div>
                  );
                case "pieza":
                  return (
                    <div key={index} className="absolute w-full z-20">
                      <PiezaCompleta
                        cara={p}
                        diente={data}
                        piezaSelector={piezaSelector}
                      />
                    </div>
                  );
                case "arriba":
                  return (
                    <div key={index} className="absolute w-full">
                      <Caras cara={p} diente={data} />
                    </div>
                  );
                case "abajo":
                  return (
                    <div
                      key={index}
                      className="transform rotate-180 absolute w-full bottom-0"
                    >
                      <Caras cara={p} diente={data} />
                    </div>
                  );
                case "derecha":
                  return (
                    <div
                      key={index}
                      className="transform rotate-90 absolute w-10 cara-right z-10"
                    >
                      <Caras cara={p} diente={data} />
                    </div>
                  );
                case "izquierda":
                  return (
                    <div
                      key={index}
                      className="transform -rotate-90 absolute w-10 cara-left z-10"
                    >
                      <Caras cara={p} diente={data} />
                    </div>
                  );
                case "centro":
                  return (
                    <div key={index} className="transform absolute h-10 w-10">
                      <div className="flex justify-center items-center h-full">
                        <Caras cara={p} diente={data} />
                      </div>
                    </div>
                  );
                default:
                  <div></div>;
              }
            })
          : null}
      </div>
      {piezaSelector ? null : (
        <p className="font-bold text-xs text-center">{data.id}</p>
      )}
    </div>
  );
}

export default Pieza;
