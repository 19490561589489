import React, { useContext, useEffect, useState } from "react";
import DientesContext from "../../context/dientes/dientesContext";

import Builder from "../builder/Builder";
import TrabajosSelect from "./TrabajosSelect";

function SelectedItem() {
  const dientesContext = useContext(DientesContext);
  const {
    dienteSeleccionado,
    modalOpen,
    setModalOpen,
    guardarDiente,
    guardarDienteTemporario,
    caraSeleccionada,
    checkRango,
  } = dientesContext;
  const pacienteData = [dienteSeleccionado];

  const { nombre, id } = dienteSeleccionado;

  if (modalOpen) {
    return (
      <div
        className="fixed z-20 inset-0 overflow-y-auto"
        aria-labelledby="modal-title"
        role="dialog"
        aria-modal="true"
      >
        <div className="flex items-center justify-center h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
          <div
            className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity"
            aria-hidden="true"
          ></div>
          <span
            className="hidden sm:inline-block sm:align-middle sm:h-screen"
            aria-hidden="true"
          >
            &#8203;
          </span>
          <div className="inline-block align-bottom w-full bg-white rounded-lg text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full">
            <div className="bg-white px-4 pt-5 pb-4 sm:p-6 sm:pb-4">
              <div>
                <div className="relative">
                  <div className="mt-6 mb-3">
                  <Builder pacienteData={pacienteData} piezaSelector={true} />
                  </div>
                  <div>
                    <p className="font-bold text-xl">{nombre} - {id}</p>
                    {caraSeleccionada.posicion ? (
                      <p className="text-lg mb-4">
                        Posición: {caraSeleccionada.nombrePosicion}
                      </p>
                    ) : null}
                  </div>
                </div>
                <div>
                  <TrabajosSelect />
                </div>
              </div>
            </div>
            <div className="bg-gray-50 px-4 py-3 flex items-center">
              {checkRango(dienteSeleccionado.id, 51, 85) ? (
                <button
                  onClick={() => guardarDienteTemporario()}
                  type="button"
                  className="w-full mr-3 inline-flex justify-center rounded-md px-4 py-2 bg-green-500 text-base font-medium text-green-800 hover:bg-green-600 hover:text-white focus:outline-none sm:w-auto"
                >
                  Añadir
                </button>
              ) : (
                <button
                  onClick={() => guardarDiente()}
                  type="button"
                  className="w-full inline-flex mr-3 justify-center rounded-md px-4 py-2  bg-green-500 text-base font-medium text-green-800 hover:bg-green-600 hover:text-white focus:outline-none sm:w-auto"
                >
                  Añadir
                </button>
              )}

              <button
                onClick={() => setModalOpen(false)}
                type="button"
                className="w-full inline-flex justify-center rounded-md shadow-sm px-4 py-2 bg-red-500 text-base font-medium text-gray-200 hover:bg-red-600 hover:text-white focus:outline-none sm:w-auto"
              >
                Cancelar
              </button>
            </div>
          </div>
        </div>
      </div>
    );
  } else {
    return <div></div>;
  }
}

export default SelectedItem;
