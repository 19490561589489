import React, { useReducer, useEffect, useState, useContext } from "react";
import authContext from "./authContext";
import authReducer from "./authReducer";
import axiosClient from "../../config/axios";
import AlertContext from "../alerts/alertContext";

const AuthState = (props) => {
  const alertContext = useContext(AlertContext);
  const { setAlert } = alertContext;

  const initialState = {};
  const [state, dispatch] = useReducer(authReducer, initialState);

  const [datosUsuario, setDatosUsuario] = useState({});
  const [loadingUsuario, setLoadingUsuario] = useState(false);
  const [canEdit, setCanEdit] = useState(false);
  const [datosOdon, setDatosOdon] = useState({});

  const [loadingDatosOdontograma, setLoadingDatosOdontograma] = useState(false);

  const getUser = async (data) => {
    setLoadingUsuario(true);
    const header = {
      headers: {
        Authorization: "Bearer " + data,
      },
    };

    try {
      const response = await axiosClient.get("key/self", header);
      setDatosUsuario(response.data.data);
      setLoadingUsuario(false);
    } catch (error) {
      setAlert(error.response.data.message, "red-300");
      setLoadingUsuario(true);
    }
  };
  const cambiarCanEdit = (data) => {
    setCanEdit(data);
  };
  const buscarOdontograma = async (datos) => {
    const header = {
      headers: {
        Authorization: "Bearer " + datos.apikey,
      },
    };

    setLoadingDatosOdontograma(true);
    try {
      const response = await axiosClient.get(
        `odontograma/obtener/odontograma/${datos.odonID}`,
        header
      );
      // console.log(response.data);
      setDatosOdon(response.data.data);
      setLoadingDatosOdontograma(false);
    } catch (error) {
      setLoadingDatosOdontograma(true);
      setDatosOdon({});
      setAlert(error.response.data.message, "red-300");
    }
  };

  return (
    <authContext.Provider
      value={{
        cambiarCanEdit,
        getUser,
        datosUsuario,
        loadingUsuario,
        canEdit,
        buscarOdontograma,
        loadingDatosOdontograma,
        datosOdon,
      }}
    >
      {props.children}
    </authContext.Provider>
  );
};

export default AuthState;
