import React from "react";

function TrabajoIconos({ nombre, color, estado }) {
  switch (nombre) {
    case "corona":
      return (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 200 200"
          className="h-7 w-7"
          fill={color}
          stroke={color}
        >
          <path d="M100,200A100,100,0,1,1,200,100,100.11,100.11,0,0,1,100,200Zm0-183.33A83.33,83.33,0,1,0,183.33,100,83.42,83.42,0,0,0,100,16.67Z" />
        </svg>
      );
    case "en-erupcion":
      return (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 200 200"
          className="h-7 w-7"
          fill={color}
          stroke={color}
        >
          <polygon points="176.26 92.93 109.56 159.63 110 16.7 90 16.64 89.57 158.76 23.74 92.93 9.6 107.07 100 197.48 190.4 107.07 176.26 92.93" />
        </svg>
      );
    case "no-erupcionada":
      return (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 200 200"
          className="h-7 w-7"
          fill={color}
          stroke={color}
        >
          <polygon points="115.96 100.5 178 38.46 161.54 22 99.5 84.04 37.46 22 21 38.46 83.04 100.5 21 162.54 37.46 179 99.5 116.96 161.54 179 178 162.54 115.96 100.5" />
        </svg>
      );
    case "extraccion":
      return estado === "realizado" ? (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 200 200"
          className="h-7 w-7"
          fill={color}
          stroke={color}
        >
          <rect x="17" y="50" width="166" height="25.47" />
          <rect x="17" y="125.07" width="166" height="25.47" />
        </svg>
      ) : (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 200 200"
          className="h-7 w-7"
          fill={color}
          stroke={color}
        >
          <rect x="17" y="50" width="166" height="25.47" />
          <rect x="17" y="125.07" width="166" height="25.47" />
        </svg>
      );
    case "implante-corona":
      return (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 200 200"
          className="h-7 w-7"
          fill={color}
          stroke={color}
        >
          <path d="M66.83,133H51.11V67.23H66.83Z" />
          <path d="M98.49,67.23,113.08,113l14.56-45.72h20.74V133h-15.8V117.6l1.52-31.41L118.29,133H107.88L92,86.14l1.53,31.46V133H77.78V67.23Z" />

          <path d="M100,200A100,100,0,1,1,200,100,100.11,100.11,0,0,1,100,200Zm0-183.33A83.33,83.33,0,1,0,183.33,100,83.42,83.42,0,0,0,100,16.67Z" />
        </svg>
      );
    case "implante":
      return (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 200 200"
          className="h-7 w-7"
          fill={color}
          stroke={color}
        >
          <path d="M44.82,156.58H18V44.43H44.82Z" />
          <path d="M98.85,44.43l24.91,78,24.83-78H184V156.58H157V130.39l2.61-53.61-27,79.8H114.87L87.82,76.7l2.61,53.69v26.19H63.52V44.43Z" />
        </svg>
      );

    case "incrustacion":
      return (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 200 200"
          className="h-7 w-7"
          fill={color}
          stroke={color}
        >
          <path d="M57.08,157h-27V44.23h27Z" />
          <path d="M169.59,157H142.7L102.93,86.83V157H75.88V44.23h27.05l39.69,70.17V44.23h27Z" />
        </svg>
      );
    case "sellante":
      return (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 200 200"
          className="h-7 w-7"
          fill={color}
          stroke={color}
        >
          <path d="M99,103.66q-11.1-3.21-16.15-7.88a15.08,15.08,0,0,1-5-11.53A16,16,0,0,1,83.92,71.4Q90.1,66.33,100,66.33a26.74,26.74,0,0,1,12,2.61,19.83,19.83,0,0,1,8.18,7.23,18.52,18.52,0,0,1,2.89,10.06H114.4q0-6-3.78-9.4T100,73.41q-6.37,0-10,2.83a9.44,9.44,0,0,0-3.57,7.84,8.49,8.49,0,0,0,3.39,6.81q3.39,2.77,11.54,5.09a53.51,53.51,0,0,1,12.76,5.08,19.33,19.33,0,0,1,6.82,6.48,16.5,16.5,0,0,1,2.23,8.71A15.38,15.38,0,0,1,117,129q-6.19,4.82-16.57,4.81a30.62,30.62,0,0,1-12.58-2.59,21.17,21.17,0,0,1-9-7.11,17.44,17.44,0,0,1-3.17-10.25h8.67a11.34,11.34,0,0,0,4.38,9.41q4.38,3.45,11.7,3.46,6.82,0,10.46-2.8a9.1,9.1,0,0,0,3.64-7.63,8.93,8.93,0,0,0-3.37-7.47C108.92,107.11,104.84,105.37,99,103.66Z" />
        </svg>
      );
    case "radiografia":
      return (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 200 200"
          className="h-7 w-7"
          fill={color}
          stroke={color}
        >
          <path d="M71.66,106.36H56.3V133H47.63V67.23H69.28q11.06,0,17,5.06t6,14.71a17.77,17.77,0,0,1-3.3,10.7,19.66,19.66,0,0,1-9.18,6.81l15.36,27.9V133H85.85ZM56.3,99.28H69.55q6.42,0,10.22-3.35a11.33,11.33,0,0,0,3.79-9q0-6.11-3.61-9.36t-10.44-3.3H56.3Z" />
          <path d="M125.64,92.42l15.68-25.19h10.15L130.81,99.82,152,133H141.72l-16.08-25.64L109.48,133H99.28l21.2-33.13L99.78,67.23h10.1Z" />
        </svg>
      );
    case "enfermediad-periodontal":
      return (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 200 200"
          className="h-7 w-7"
          fill={color}
          stroke={color}
        >
          <path d="M55.3,107.22V133H46.68V67.23H70.8q10.73,0,16.82,5.51T93.7,87.32q0,9.57-6,14.73t-17,5.17Zm0-7.09H70.8q6.92,0,10.6-3.27t3.68-9.48A12.42,12.42,0,0,0,81.4,78q-3.69-3.53-10.11-3.66h-16Z" />
          <path d="M104.7,133V67.23h18.46A29.57,29.57,0,0,1,138.25,71a25.64,25.64,0,0,1,10.13,10.79A35.25,35.25,0,0,1,152,97.88v4.2a35.38,35.38,0,0,1-3.57,16.29,25.21,25.21,0,0,1-10.2,10.74A31.47,31.47,0,0,1,122.8,133Zm8.63-58.63v51.54h9.07q10,0,15.52-6.23t5.54-17.76V98q0-11.2-5.23-17.41t-14.84-6.3Z" />
        </svg>
      );
    case "tratamiento-conducto":
      return (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 200 200"
          className="h-7 w-7"
          fill={color}
          stroke={color}
        >
          <path d="M96.86,74.32h-21V133H67.26V74.32h-21V67.23H96.86Z" />
          <path d="M153.4,112.1q-1.22,10.43-7.65,16.09t-17.14,5.66q-11.58,0-18.57-8.35t-7-22.34V96.84a37.58,37.58,0,0,1,3.25-16.11,24.38,24.38,0,0,1,9.23-10.68,25.64,25.64,0,0,1,13.84-3.72q10.41,0,16.7,5.84t7.32,16.18h-8.66q-1.12-7.87-4.88-11.41t-10.48-3.53q-8.26,0-13,6.15T111.73,97v6.37q0,10.71,4.44,17a14.35,14.35,0,0,0,12.44,6.33q7.19,0,11-3.28t5.1-11.39Z" />
        </svg>
      );
    case "perno-bola":
      return (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 200 200"
          className="h-7 w-7"
          fill={color}
          stroke={color}
        >
          <path d="M57.3,107.22V133H48.68V67.23H72.8q10.73,0,16.82,5.51T95.7,87.32q0,9.57-6,14.73t-17,5.17Zm0-7.09H72.8q6.92,0,10.6-3.27t3.68-9.48A12.42,12.42,0,0,0,83.4,78q-3.69-3.53-10.11-3.66h-16Z" />
          <path d="M106.7,133V67.23h21.38q10.65,0,16,4.42t5.37,13.09a13.43,13.43,0,0,1-2.6,8.15,16.94,16.94,0,0,1-7.1,5.48,15.22,15.22,0,0,1,8.37,5.67,16.25,16.25,0,0,1,3.08,9.95q0,8.85-5.7,13.9T129.38,133Zm8.63-37.69h13q5.66,0,9.05-2.85a9.58,9.58,0,0,0,3.39-7.73q0-5.43-3.14-7.9t-9.57-2.46H115.33Zm0,6.95v23.65h14.23q6,0,9.5-3.13a11.07,11.07,0,0,0,3.48-8.65q0-11.86-12.84-11.87Z" />
        </svg>
      );
    case "perno-muñon":
      return (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 200 200"
          className="h-7 w-7"
          fill={color}
          stroke={color}
        >
          <path d="M46.3,107.22V133H37.68V67.23H61.8q10.73,0,16.82,5.51T84.7,87.32q0,9.57-6,14.73t-17,5.17Zm0-7.09H61.8q6.92,0,10.6-3.27t3.68-9.48A12.42,12.42,0,0,0,72.4,78q-3.69-3.53-10.11-3.66h-16Z" />
          <path d="M106.84,67.23l21.38,53.62L149.6,67.23h11.18V133h-8.62V107.36L153,79.73,131.5,133h-6.6L103.47,79.87l.86,27.49V133H95.7V67.23Z" />
        </svg>
      );
    default:
      return <div></div>;
  }
}

export default TrabajoIconos;
