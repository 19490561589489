import React from "react";
import frontiniLogo from "../assets/img/frontini-logo.svg";

function LoadingScreen({
  loadingUsuario,
  loadingDatosOdontograma,
  validacionUrl,
}) {
  return (
    <div className="flex h-screen bg-gray-300 p-4">
      <div className="flex-col flex ml-auto mr-auto items-center pt-40 w-full lg:w-2/4">
        <div className="flex justify-center mb-8 px-6">
          <img
            src={frontiniLogo}
            className="lg:h-20 h-16"
            alt="Logo Frontini y asoc"
          />
        </div>
        <div className="bg-white shadow-lg rounded-lg mt-2 flex flex-col lg:w-2/4 w-full">
          <div>
            {loadingUsuario && loadingDatosOdontograma ? (
              <div>
                <div className="flex justify-center">
                  <svg
                    className="animate-spin h-8 w-8 text-blue-700 mt-10"
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                  >
                    <circle
                      className="opacity-25"
                      cx="12"
                      cy="12"
                      r="10"
                      stroke="currentColor"
                      strokeWidth="4"
                    ></circle>
                    <path
                      className="opacity-75"
                      fill="currentColor"
                      d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                    ></path>
                  </svg>
                </div>
                <p className="text-gray-600 text-center text-lg py-5">
                  Cargando...
                </p>
              </div>
            ) : null}
          </div>
          {!validacionUrl ? (
            <p className="text-gray-600 text-center text-lg py-5">
              Los datos que estás consultando son incorrectos.
            </p>
          ) : null}
        </div>
      </div>
    </div>
  );
}
export default LoadingScreen;
