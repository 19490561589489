import React, { useState, useContext } from "react";
import AuthContext from "../context/auth/authContext";
import FechaHoy from "./FechaHoy";

function Nav() {
  const authContext = useContext(AuthContext);
  const { datosOdon } = authContext;

  return (
    <div>
      <div className="bg-white shadow-lg text-gray-500 font-medium rounded-t-lg xl:rounded-lg p-4 mb-0 xl:mb-4">
        <div className="flex justify-between items-center">
          <FechaHoy />
          <div className="relative hidden xl:block">
            <div className="flex items-center">
              <div className="bg-blue-100 rounded-full p-2 flex items-center">
                <p className="mx-3 text-blue-500 font-bold">
                  {datosOdon.profesional_apyn}
                </p>
                <div className="w-8 h-8 bg-blue-700 rounded-full flex items-center justify-center">
                  <svg className="w-5 h-5 text-white" viewBox="0 0 24 24">
                    <path
                      d="M15.71 12.71a6 6 0 1 0-7.42 0a10 10 0 0 0-6.22 8.18a1 1 0 0 0 2 .22a8 8 0 0 1 15.9 0a1 1 0 0 0 1 .89h.11a1 1 0 0 0 .88-1.1a10 10 0 0 0-6.25-8.19zM12 12a4 4 0 1 1 4-4a4 4 0 0 1-4 4z"
                      fill="currentColor"
                    ></path>
                  </svg>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="block xl:hidden bg-blue-100 p-4 rounded-b-lg shadow-lg mb-5">
        <div className="flex items-center">
          <div className="w-8 h-8 bg-blue-700 rounded-full flex items-center justify-center">
            <svg className="w-5 h-5 text-white" viewBox="0 0 24 24">
              <path
                d="M15.71 12.71a6 6 0 1 0-7.42 0a10 10 0 0 0-6.22 8.18a1 1 0 0 0 2 .22a8 8 0 0 1 15.9 0a1 1 0 0 0 1 .89h.11a1 1 0 0 0 .88-1.1a10 10 0 0 0-6.25-8.19zM12 12a4 4 0 1 1 4-4a4 4 0 0 1-4 4z"
                fill="currentColor"
              ></path>
            </svg>
          </div>
          <p className="mx-3 text-blue-500 font-bold">
            {datosOdon.profesional_apyn}
          </p>
        </div>
      </div>
    </div>
  );
}
export default Nav;
