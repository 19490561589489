import React, { useContext } from "react";
import Footer from "./Footer";
import Nav from "./Nav";

function BaseTemplate(props) {

  return (
    <div className="p-4 xl:p-10">
      <div>
        <Nav />
        <div>{props.children}</div>
      </div>
      <Footer />
    </div>
  );
}
export default BaseTemplate;
