import React, { useContext, useEffect } from "react";
import DientesContext from "../context/dientes/dientesContext";

import Cuadrantes from "../components/builder/Cuadrantes";
import SelectedItem from "../components/modal/SelectedItem";

function HomeOdontograma() {
  const dientesContext = useContext(DientesContext);
  const { pacienteData, pacienteTemporalesData } = dientesContext;

  return (
    <div className="overflow-x-auto py-8">
      <div className="w-test flex-grow flex justify-center mb-20 relative">
        <div className="grid grid-cols-2 gap-14">
          {pacienteData.length > 0
            ? pacienteData.map((d, index) => (
                <Cuadrantes
                  key={index}
                  pacienteData={d}
                  piezaSelector={false}
                />
              ))
            : null}
        </div>
      </div>
      <div className="w-test flex-grow flex justify-center relative">
        <div className="grid grid-cols-2 gap-14">
          {pacienteTemporalesData.length > 0
            ? pacienteTemporalesData.map((d, index) => (
                <Cuadrantes
                  key={index}
                  pacienteData={d}
                  piezaSelector={false}
                />
              ))
            : null}
        </div>
      </div>
      <div className="mt-6">
        <SelectedItem />
      </div>
    </div>
  );
}

export default HomeOdontograma;
