import React, { useReducer, useEffect, useState, useContext } from "react";
import dientesContext from "./dientesContext";
import dientesReducer from "./dientesReducer";
import axiosClient from "../../config/axios";
import AlertContext from "../alerts/alertContext";
import AuthContext from "../auth/authContext";

import {
  DIENTE_SELECCIONADO,
  GET_PACIENTE_DATA,
  GET_PACIENTE_TEMPORALES_DATA,
  CARA_SELECCIONADA,
  GET_TRABAJOS,
  FILTER_TRABAJOS,
  CAMBIAR_ESTADO,
} from "../../types/index.js";

import {
  bocaCompletaAdulto,
  bocaCompletaTemporales,
} from "../../types/bocaCompleta.js";

const DientesState = (props) => {
  const alertContext = useContext(AlertContext);
  const { setAlert } = alertContext;

  const authContext = useContext(AuthContext);
  const { idOdontograma } = authContext;

  const [trabajosOdontograma, setTrabajosOdontograma] = useState([{}]);
  const [trabajosDelBack, setTrabajosDelBack] = useState([{}]);

  const [trabajosAgregados, setTrabajosAgregados] = useState([]);
  const [trabajosParaGuardar, setTrabajosParaGuardar] = useState([]);

  const [piezasEnBoca, setPiezasEnBoca] = useState(0);

  const initialState = {
    pacienteData: bocaCompletaAdulto,
    pacienteTemporalesData: bocaCompletaTemporales,
    dienteSeleccionado: {},
    caraSeleccionada: {},
    trabajos: [],
    trabajosSector: [],
    estadoSeleccionado: "arealizar",
  };
  const [state, dispatch] = useReducer(dientesReducer, initialState);
  const [modalOpen, setModalOpen] = useState(false);

  useEffect(() => {
    agregarTrabajosGuardados();
    recorerObjetoBoca();
  }, [trabajosOdontograma.length, trabajosDelBack.length]);

  useEffect(() => {
    contarPiezas();
  }, [state.pacienteData, state.pacienteTemporalesData]);

  const obtenerTrabajosOdontograma = async (apikey, datos) => {
    if (Object.keys(datos).length > 0) {
      const header = {
        headers: {
          Authorization: "Bearer " + apikey,
        },
      };
      const data = {
        id_odontograma: datos.id,
        codos: datos.codos,
        dni_paciente: datos.paciente_dni,
      };
      try {
        const response = await axiosClient.post(
          "odontograma/obtener/odontograma",
          data,
          header
        );
        setTrabajosOdontograma(response.data.data.trabajosOdon);
      } catch (error) {
        setTrabajosOdontograma([{}]);
        setAlert(error.response.data.message, "red-300");
      }
    }
  };

  //formatea el endpoint de trabajos del back para que se adapte al front
  const agregarTrabajosGuardados = () => {
    if (trabajosOdontograma.length > 1) {
      let dientes = [];
      trabajosOdontograma.map((p) => {
        p.piezas.map((m) => {
          if (p.caras.length > 0) {
            dientes.push({
              id: m.numero,
              posiciones: [
                {
                  nombrePosicion: p.caras[0].nombre,
                  id_trabajo_odon: p.id ? p.id : "",
                  trabajo: setTrabajoByID(p.trabajo_id),
                  posicion: p.posicion,
                  alcance: p.alcance,
                },
              ],
            });
          }
        });
      });
      setTrabajosDelBack(dientes);
    }
  };

  //busca el id por trabajo y lo matchea con el id proveniente del trabajo back
  const setTrabajoByID = (id) => {
    let value = "";
    if (state.trabajos.length > 0) {
      state.trabajos.map((lista) => {
        if (lista.id === id) {
          value = lista;
        }
      });
    }
    return value;
  };

  //recorre el objeto inicial del odontograma y le asigna cada dibujo proveniente del back
  const recorerObjetoBoca = () => {
    const copiaBocaCompletaAdulto = JSON.parse(
      JSON.stringify(state.pacienteData)
    );
    copiaBocaCompletaAdulto.map((cuadrantes) => {
      cuadrantes.data.map((dientes) => {
        trabajosDelBack.map((t) => {
          if (t.id === dientes.id) {
            let posicion = dientes.posiciones.findIndex(
              (pos) => pos.posicion === t.posiciones[0].posicion
            );

            dientes.posiciones[posicion] = t.posiciones[0];
          }
        });
      });
    });

    const copiaBocaCompletaTemporales = JSON.parse(
      JSON.stringify(state.pacienteTemporalesData)
    );
    copiaBocaCompletaTemporales.map((cuadrantes) => {
      cuadrantes.data.map((dientes) => {
        trabajosDelBack.map((t) => {
          if (t.id === dientes.id) {
            let posicion = dientes.posiciones.findIndex(
              (pos) => pos.posicion === t.posiciones[0].posicion
            );
            dientes.posiciones[posicion] = t.posiciones[0];
          }
        });
      });
    });

    //guarda el nuevo objeto del data paciente dependiendo de los datos del back
    dispatch({
      type: GET_PACIENTE_DATA,
      payload: copiaBocaCompletaAdulto,
    });

    //guarda el nuevo objeto del data paciente temporarios dependiendo de los datos del back
    dispatch({
      type: GET_PACIENTE_TEMPORALES_DATA,
      payload: copiaBocaCompletaTemporales,
    });
  };

  const obtenerListaTrabajos = async (apikey, datos) => {
    if (Object.keys(datos).length > 0) {
      const header = {
        headers: {
          Authorization: "Bearer " + apikey,
        },
      };

      const data = {
        codos: datos.codos,
        dni_paciente: datos.paciente_dni,
      };
      try {
        const response = await axiosClient.post(
          "odontograma/obtener/trabajos",
          data,
          header
        );

          // console.log(response.data.data)
          // console.log(response.data.data.filter(trabajo => trabajo.alcance === "exterior-pieza" && trabajo.estado === "arealizar"))


        dispatch({
          type: GET_TRABAJOS,
          payload: response.data.data,
        });
      } catch (error) {
        dispatch({
          type: GET_TRABAJOS,
          payload: [],
        });
      }
    }
  };

  const getSeleccionadoData = (data) => {
    dispatch({
      type: DIENTE_SELECCIONADO,
      payload: data,
    });
  };

  const getCaraData = (data) => {
    dispatch({
      type: CARA_SELECCIONADA,
      payload: data,
    });
  };

  const trabajosPorSector = (alcance, estado) => {
    const newTrabajos = state.trabajos.filter(
      (t) => t.alcance === alcance && t.estado === estado
    );
    dispatch({
      type: FILTER_TRABAJOS,
      payload: newTrabajos,
    });
  };

  const cambiarEstadoSeleccionado = (data) => {
    dispatch({
      type: CAMBIAR_ESTADO,
      payload: data,
    });
  };

  const mutarDiente = (data) => {
    const dienteClon = JSON.parse(JSON.stringify(state.dienteSeleccionado));
    const cara = JSON.parse(JSON.stringify(state.caraSeleccionada));

    dienteClon.posiciones.forEach((element) => {
      if (element.posicion === cara.posicion) {
        element.trabajo = data;
      }
    });

    dispatch({
      type: DIENTE_SELECCIONADO,
      payload: dienteClon,
    });
  };

  const guardarDiente = () => {
    let dienteModif = state.dienteSeleccionado;
    let pacienteCopy = state.pacienteData;
    pacienteCopy.forEach((element) => {
      element.data.forEach((diente) => {
        if (diente.id === dienteModif.id) {
          diente.posiciones = dienteModif.posiciones;
          transformarDataParaGuardar(diente);
        }
      });
    });
    contarPiezas();
    setTrabajosAgregados([...trabajosAgregados, dienteModif]);
    setModalOpen(false);
  };

  const contarPiezas = () => {
    let piezasAdulto = state.pacienteData;
    let piezasTemp = state.pacienteTemporalesData;

    let cantidad = 0;
    piezasAdulto.map((cuadrante) => {
      cuadrante.data.map((pieza) => {
        cantidad += 1;
        pieza.posiciones.map((pos) => {
          if (pos.trabajo.descuenta) {
            cantidad -= 1;
          }
        });
      });
    });
    piezasTemp.map((cuadrante) => {
      cuadrante.data.map((pieza) => {
        cantidad += 1;
        pieza.posiciones.map((pos) => {
          if (pos.trabajo.descuenta) {
            cantidad -= 1;
          }
        });
      });
    });
    setPiezasEnBoca(cantidad);
  };

  const transformarDataParaGuardar = (data) => {
    let arrayFinal = [...trabajosParaGuardar];
    data.posiciones.map((posicion) => {
      if (posicion.id_trabajo_odon !== "" || posicion.trabajo !== "") {
        let trabajo = {
          id_trabajo_odon: posicion.id_trabajo_odon
            ? posicion.id_trabajo_odon
            : "",
          id_trabajo: posicion.trabajo.id ? posicion.trabajo.id : "",
          posicion: posicion.posicion ? posicion.posicion : "",
          alcance: posicion.trabajo.alcance ? posicion.trabajo.alcance : "",
          estado: posicion.trabajo.estado ? posicion.trabajo.estado : "",
          pieza_numero: data.id ? data.id : "",
          cara_nombre: posicion.nombrePosicion ? posicion.nombrePosicion : "",
          pieza_coordenadas: "",
        };
        arrayFinal.push(trabajo);
      }
    });
    setTrabajosParaGuardar(arrayFinal);
  };

  const guardarOdontograma = async (datos) => {
    const header = {
      headers: {
        Authorization: "Bearer " + datos.apikey,
      },
    };

    const data = {
      id_odontograma: datos.odonID,
      trabajos: trabajosParaGuardar,
    };

    try {
      const response = await axiosClient.post(
        "odontograma/guardar/trabajos/odontograma",
        data,
        header
      );
      setAlert(response.data.data, "green-300");
      setTrabajosAgregados([]);
      setTrabajosParaGuardar([]);
    } catch (error) {
      setAlert(error.response.data.data, "red-300");
      setTrabajosAgregados([]);
      setTrabajosParaGuardar([]);
    }
  };

  const guardarDienteTemporario = () => {
    let dienteModif = state.dienteSeleccionado;
    let pacienteCopy = state.pacienteTemporalesData;

    pacienteCopy.forEach((element) => {
      element.data.forEach((diente) => {
        if (diente.id === dienteModif.id) {
          diente.posiciones = dienteModif.posiciones;
          transformarDataParaGuardar(diente);
        }
      });
    });
    contarPiezas();
    setTrabajosAgregados([...trabajosAgregados, dienteModif]);
    setModalOpen(false);
  };

  function checkRango(x, min, max) {
    return x >= min && x <= max;
  }

  return (
    <dientesContext.Provider
      value={{
        pacienteData: state.pacienteData,
        pacienteTemporalesData: state.pacienteTemporalesData,
        dienteSeleccionado: state.dienteSeleccionado,
        caraSeleccionada: state.caraSeleccionada,
        trabajos: state.trabajos,
        trabajosSector: state.trabajosSector,
        estadoSeleccionado: state.estadoSeleccionado,
        dienteMutado: state.dienteMutado,
        obtenerTrabajosOdontograma,
        obtenerListaTrabajos,
        trabajosPorSector,
        agregarTrabajosGuardados,
        recorerObjetoBoca,
        getSeleccionadoData,
        getCaraData,
        setModalOpen,
        cambiarEstadoSeleccionado,
        modalOpen,
        mutarDiente,
        guardarDiente,
        guardarDienteTemporario,
        checkRango,
        piezasEnBoca,
        guardarOdontograma,
        contarPiezas,
        trabajosAgregados,
        setTrabajosAgregados,
        trabajosParaGuardar,
        setTrabajosParaGuardar,
      }}
    >
      {props.children}
    </dientesContext.Provider>
  );
};

export default DientesState;
