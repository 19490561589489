export let bocaCompletaAdulto = [
  {
    cuadrante: 1,
    data: [
      {
        id: 18,
        nombre: "Tercer Molar",
        posiciones: [
          {
            id_trabajo_odon: "",
            trabajo: "",
            posicion: "pieza",
            nombrePosicion: "Pieza",
            alcance: "pieza",
          },
          {
            id_trabajo_odon: "",
            trabajo: "",
            posicion: "centro",
            nombrePosicion: "Oclusal",
            alcance: "cara",
          },
          {
            id_trabajo_odon: "",
            trabajo: "",
            posicion: "izquierda",
            nombrePosicion: "Distal",
            alcance: "cara",
          },
          {
            id_trabajo_odon: "",
            trabajo: "",
            posicion: "derecha",
            nombrePosicion: "Mesial",
            alcance: "cara",
          },
          {
            id_trabajo_odon: "",
            trabajo: "",
            posicion: "arriba",
            nombrePosicion: "Vestibular",
            alcance: "cara",
          },
          {
            id_trabajo_odon: "",
            trabajo: "",
            posicion: "abajo",
            nombrePosicion: "Palatino",
            alcance: "cara",
          },
          {
            id_trabajo_odon: "",
            trabajo: "",
            posicion: "arriba-pieza",
            nombrePosicion: "Exterior-Arriba",
            alcance: "exterior-pieza",
          },
          {
            id_trabajo_odon: "",
            trabajo: "",
            posicion: "abajo-pieza",
            nombrePosicion: "Exterior-Abajo",
            alcance: "exterior-pieza",
          },
        ],
      },
      {
        id: 17,
        nombre: "Segundo Molar",
        posiciones: [
          {
            id_trabajo_odon: "",
            trabajo: "",
            posicion: "pieza",
            nombrePosicion: "Pieza",
            alcance: "pieza",
          },
          {
            id_trabajo_odon: "",
            trabajo: "",
            posicion: "centro",
            nombrePosicion: "Oclusal",
            alcance: "cara",
          },
          {
            id_trabajo_odon: "",
            trabajo: "",
            posicion: "izquierda",
            nombrePosicion: "Distal",
            alcance: "cara",
          },
          {
            id_trabajo_odon: "",
            trabajo: "",
            posicion: "derecha",
            nombrePosicion: "Mesial",
            alcance: "cara",
          },
          {
            id_trabajo_odon: "",
            trabajo: "",
            posicion: "arriba",
            nombrePosicion: "Vestibular",
            alcance: "cara",
          },
          {
            id_trabajo_odon: "",
            trabajo: "",
            posicion: "abajo",
            nombrePosicion: "Palatino",
            alcance: "cara",
          },
          {
            id_trabajo_odon: "",
            trabajo: "",
            posicion: "arriba-pieza",
            nombrePosicion: "Exterior-Arriba",
            alcance: "exterior-pieza",
          },
          {
            id_trabajo_odon: "",
            trabajo: "",
            posicion: "abajo-pieza",
            nombrePosicion: "Exterior-Abajo",
            alcance: "exterior-pieza",
          },
        ],
      },
      {
        id: 16,
        nombre: "Primer Molar",
        posiciones: [
          {
            id_trabajo_odon: "",
            trabajo: "",
            posicion: "pieza",
            nombrePosicion: "Pieza",
            alcance: "pieza",
          },
          {
            id_trabajo_odon: "",
            trabajo: "",
            posicion: "centro",
            nombrePosicion: "Oclusal",
            alcance: "cara",
          },
          {
            id_trabajo_odon: "",
            trabajo: "",
            posicion: "izquierda",
            nombrePosicion: "Distal",
            alcance: "cara",
          },
          {
            id_trabajo_odon: "",
            trabajo: "",
            posicion: "derecha",
            nombrePosicion: "Mesial",
            alcance: "cara",
          },
          {
            id_trabajo_odon: "",
            trabajo: "",
            posicion: "arriba",
            nombrePosicion: "Vestibular",
            alcance: "cara",
          },
          {
            id_trabajo_odon: "",
            trabajo: "",
            posicion: "abajo",
            nombrePosicion: "Palatino",
            alcance: "cara",
          },
          {
            id_trabajo_odon: "",
            trabajo: "",
            posicion: "arriba-pieza",
            nombrePosicion: "Exterior-Arriba",
            alcance: "exterior-pieza",
          },
          {
            id_trabajo_odon: "",
            trabajo: "",
            posicion: "abajo-pieza",
            nombrePosicion: "Exterior-Abajo",
            alcance: "exterior-pieza",
          },
        ],
      },
      {
        id: 15,
        nombre: "Segundo Premolar",
        posiciones: [
          {
            id_trabajo_odon: "",
            trabajo: "",
            posicion: "pieza",
            nombrePosicion: "Pieza",
            alcance: "pieza",
          },
          {
            id_trabajo_odon: "",
            trabajo: "",
            posicion: "centro",
            nombrePosicion: "Oclusal",
            alcance: "cara",
          },
          {
            id_trabajo_odon: "",
            trabajo: "",
            posicion: "izquierda",
            nombrePosicion: "Distal",
            alcance: "cara",
          },
          {
            id_trabajo_odon: "",
            trabajo: "",
            posicion: "derecha",
            nombrePosicion: "Mesial",
            alcance: "cara",
          },
          {
            id_trabajo_odon: "",
            trabajo: "",
            posicion: "arriba",
            nombrePosicion: "Vestibular",
            alcance: "cara",
          },
          {
            id_trabajo_odon: "",
            trabajo: "",
            posicion: "abajo",
            nombrePosicion: "Palatino",
            alcance: "cara",
          },
          {
            id_trabajo_odon: "",
            trabajo: "",
            posicion: "arriba-pieza",
            nombrePosicion: "Exterior-Arriba",
            alcance: "exterior-pieza",
          },
          {
            id_trabajo_odon: "",
            trabajo: "",
            posicion: "abajo-pieza",
            nombrePosicion: "Exterior-Abajo",
            alcance: "exterior-pieza",
          },
        ],
      },
      {
        id: 14,
        nombre: "Primer Premolar",
        posiciones: [
          {
            id_trabajo_odon: "",
            trabajo: "",
            posicion: "pieza",
            nombrePosicion: "Pieza",
            alcance: "pieza",
          },
          {
            id_trabajo_odon: "",
            trabajo: "",
            posicion: "centro",
            nombrePosicion: "Oclusal",
            alcance: "cara",
          },
          {
            id_trabajo_odon: "",
            trabajo: "",
            posicion: "izquierda",
            nombrePosicion: "Distal",
            alcance: "cara",
          },
          {
            id_trabajo_odon: "",
            trabajo: "",
            posicion: "derecha",
            nombrePosicion: "Mesial",
            alcance: "cara",
          },
          {
            id_trabajo_odon: "",
            trabajo: "",
            posicion: "arriba",
            nombrePosicion: "Vestibular",
            alcance: "cara",
          },
          {
            id_trabajo_odon: "",
            trabajo: "",
            posicion: "abajo",
            nombrePosicion: "Palatino",
            alcance: "cara",
          },
          {
            id_trabajo_odon: "",
            trabajo: "",
            posicion: "arriba-pieza",
            nombrePosicion: "Exterior-Arriba",
            alcance: "exterior-pieza",
          },
          {
            id_trabajo_odon: "",
            trabajo: "",
            posicion: "abajo-pieza",
            nombrePosicion: "Exterior-Abajo",
            alcance: "exterior-pieza",
          },
        ],
      },
      {
        id: 13,
        nombre: "Canino",
        posiciones: [
          {
            id_trabajo_odon: "",
            trabajo: "",
            posicion: "pieza",
            nombrePosicion: "Pieza",
            alcance: "pieza",
          },
          {
            id_trabajo_odon: "",
            trabajo: "",
            posicion: "centro",
            nombrePosicion: "Incisal",
            alcance: "cara",
          },
          {
            id_trabajo_odon: "",
            trabajo: "",
            posicion: "izquierda",
            nombrePosicion: "Distal",
            alcance: "cara",
          },
          {
            id_trabajo_odon: "",
            trabajo: "",
            posicion: "derecha",
            nombrePosicion: "Mesial",
            alcance: "cara",
          },
          {
            id_trabajo_odon: "",
            trabajo: "",
            posicion: "arriba",
            nombrePosicion: "Vestibular",
            alcance: "cara",
          },
          {
            id_trabajo_odon: "",
            trabajo: "",
            posicion: "abajo",
            nombrePosicion: "Palatino",
            alcance: "cara",
          },
          {
            id_trabajo_odon: "",
            trabajo: "",
            posicion: "arriba-pieza",
            nombrePosicion: "Exterior-Arriba",
            alcance: "exterior-pieza",
          },
          {
            id_trabajo_odon: "",
            trabajo: "",
            posicion: "abajo-pieza",
            nombrePosicion: "Exterior-Abajo",
            alcance: "exterior-pieza",
          },
        ],
      },
      {
        id: 12,
        nombre: "Incisivo Lateral",
        posiciones: [
          {
            id_trabajo_odon: "",
            trabajo: "",
            posicion: "pieza",
            nombrePosicion: "Pieza",
            alcance: "pieza",
          },
          {
            id_trabajo_odon: "",
            trabajo: "",
            posicion: "centro",
            nombrePosicion: "Incisal",
            alcance: "cara",
          },
          {
            id_trabajo_odon: "",
            trabajo: "",
            posicion: "izquierda",
            nombrePosicion: "Distal",
            alcance: "cara",
          },
          {
            id_trabajo_odon: "",
            trabajo: "",
            posicion: "derecha",
            nombrePosicion: "Mesial",
            alcance: "cara",
          },
          {
            id_trabajo_odon: "",
            trabajo: "",
            posicion: "arriba",
            nombrePosicion: "Vestibular",
            alcance: "cara",
          },
          {
            id_trabajo_odon: "",
            trabajo: "",
            posicion: "abajo",
            nombrePosicion: "Palatino",
            alcance: "cara",
          },
          {
            id_trabajo_odon: "",
            trabajo: "",
            posicion: "arriba-pieza",
            nombrePosicion: "Exterior-Arriba",
            alcance: "exterior-pieza",
          },
          {
            id_trabajo_odon: "",
            trabajo: "",
            posicion: "abajo-pieza",
            nombrePosicion: "Exterior-Abajo",
            alcance: "exterior-pieza",
          },
        ],
      },
      {
        id: 11,
        nombre: "Incisivo Central",
        posiciones: [
          {
            id_trabajo_odon: "",
            trabajo: "",
            posicion: "pieza",
            nombrePosicion: "Pieza",
            alcance: "pieza",
          },
          {
            id_trabajo_odon: "",
            trabajo: "",
            posicion: "centro",
            nombrePosicion: "Incisal",
            alcance: "cara",
          },
          {
            id_trabajo_odon: "",
            trabajo: "",
            posicion: "izquierda",
            nombrePosicion: "Distal",
            alcance: "cara",
          },
          {
            id_trabajo_odon: "",
            trabajo: "",
            posicion: "derecha",
            nombrePosicion: "Mesial",
            alcance: "cara",
          },
          {
            id_trabajo_odon: "",
            trabajo: "",
            posicion: "arriba",
            nombrePosicion: "Vestibular",
            alcance: "cara",
          },
          {
            id_trabajo_odon: "",
            trabajo: "",
            posicion: "abajo",
            nombrePosicion: "Palatino",
            alcance: "cara",
          },
          {
            id_trabajo_odon: "",
            trabajo: "",
            posicion: "arriba-pieza",
            nombrePosicion: "Exterior-Arriba",
            alcance: "exterior-pieza",
          },
          {
            id_trabajo_odon: "",
            trabajo: "",
            posicion: "abajo-pieza",
            nombrePosicion: "Exterior-Abajo",
            alcance: "exterior-pieza",
          },
        ],
      },
    ],
  },
  {
    cuadrante: 2,
    data: [
      {
        id: 21,
        nombre: "Incisivo Central",
        posiciones: [
          {
            id_trabajo_odon: "",
            trabajo: "",
            posicion: "pieza",
            nombrePosicion: "Pieza",
            alcance: "pieza",
          },
          {
            id_trabajo_odon: "",
            trabajo: "",
            posicion: "centro",
            nombrePosicion: "Incisal",
            alcance: "cara",
          },
          {
            id_trabajo_odon: "",
            trabajo: "",
            posicion: "izquierda",
            nombrePosicion: "Mesial",
            alcance: "cara",
          },
          {
            id_trabajo_odon: "",
            trabajo: "",
            posicion: "derecha",
            nombrePosicion: "Distal",
            alcance: "cara",
          },
          {
            id_trabajo_odon: "",
            trabajo: "",
            posicion: "arriba",
            nombrePosicion: "Vestibular",
            alcance: "cara",
          },
          {
            id_trabajo_odon: "",
            trabajo: "",
            posicion: "abajo",
            nombrePosicion: "Palatino",
            alcance: "cara",
          },
          {
            id_trabajo_odon: "",
            trabajo: "",
            posicion: "arriba-pieza",
            nombrePosicion: "Exterior-Arriba",
            alcance: "exterior-pieza",
          },
          {
            id_trabajo_odon: "",
            trabajo: "",
            posicion: "abajo-pieza",
            nombrePosicion: "Exterior-Abajo",
            alcance: "exterior-pieza",
          },
        ],
      },
      {
        id: 22,
        nombre: "Incisivo Lateral",
        posiciones: [
          {
            id_trabajo_odon: "",
            trabajo: "",
            posicion: "pieza",
            nombrePosicion: "Pieza",
            alcance: "pieza",
          },
          {
            id_trabajo_odon: "",
            trabajo: "",
            posicion: "centro",
            nombrePosicion: "Incisal",
            alcance: "cara",
          },
          {
            id_trabajo_odon: "",
            trabajo: "",
            posicion: "izquierda",
            nombrePosicion: "Mesial",
            alcance: "cara",
          },
          {
            id_trabajo_odon: "",
            trabajo: "",
            posicion: "derecha",
            nombrePosicion: "Distal",
            alcance: "cara",
          },
          {
            id_trabajo_odon: "",
            trabajo: "",
            posicion: "arriba",
            nombrePosicion: "Vestibular",
            alcance: "cara",
          },
          {
            id_trabajo_odon: "",
            trabajo: "",
            posicion: "abajo",
            nombrePosicion: "Palatino",
            alcance: "cara",
          },
          {
            id_trabajo_odon: "",
            trabajo: "",
            posicion: "arriba-pieza",
            nombrePosicion: "Exterior-Arriba",
            alcance: "exterior-pieza",
          },
          {
            id_trabajo_odon: "",
            trabajo: "",
            posicion: "abajo-pieza",
            nombrePosicion: "Exterior-Abajo",
            alcance: "exterior-pieza",
          },
        ],
      },
      {
        id: 23,
        nombre: "Canino",
        posiciones: [
          {
            id_trabajo_odon: "",
            trabajo: "",
            posicion: "pieza",
            nombrePosicion: "Pieza",
            alcance: "pieza",
          },
          {
            id_trabajo_odon: "",
            trabajo: "",
            posicion: "centro",
            nombrePosicion: "Incisal",
            alcance: "cara",
          },
          {
            id_trabajo_odon: "",
            trabajo: "",
            posicion: "izquierda",
            nombrePosicion: "Mesial",
            alcance: "cara",
          },
          {
            id_trabajo_odon: "",
            trabajo: "",
            posicion: "derecha",
            nombrePosicion: "Distal",
            alcance: "cara",
          },
          {
            id_trabajo_odon: "",
            trabajo: "",
            posicion: "arriba",
            nombrePosicion: "Vestibular",
            alcance: "cara",
          },
          {
            id_trabajo_odon: "",
            trabajo: "",
            posicion: "abajo",
            nombrePosicion: "Palatino",
            alcance: "cara",
          },
          {
            id_trabajo_odon: "",
            trabajo: "",
            posicion: "arriba-pieza",
            nombrePosicion: "Exterior-Arriba",
            alcance: "exterior-pieza",
          },
          {
            id_trabajo_odon: "",
            trabajo: "",
            posicion: "abajo-pieza",
            nombrePosicion: "Exterior-Abajo",
            alcance: "exterior-pieza",
          },
        ],
      },
      {
        id: 24,
        nombre: "Primer Premolar",
        posiciones: [
          {
            id_trabajo_odon: "",
            trabajo: "",
            posicion: "pieza",
            nombrePosicion: "Pieza",
            alcance: "pieza",
          },
          {
            id_trabajo_odon: "",
            trabajo: "",
            posicion: "centro",
            nombrePosicion: "Oclusal",
            alcance: "cara",
          },
          {
            id_trabajo_odon: "",
            trabajo: "",
            posicion: "izquierda",
            nombrePosicion: "Mesial",
            alcance: "cara",
          },
          {
            id_trabajo_odon: "",
            trabajo: "",
            posicion: "derecha",
            nombrePosicion: "Distal",
            alcance: "cara",
          },
          {
            id_trabajo_odon: "",
            trabajo: "",
            posicion: "arriba",
            nombrePosicion: "Vestibular",
            alcance: "cara",
          },
          {
            id_trabajo_odon: "",
            trabajo: "",
            posicion: "abajo",
            nombrePosicion: "Palatino",
            alcance: "cara",
          },
          {
            id_trabajo_odon: "",
            trabajo: "",
            posicion: "arriba-pieza",
            nombrePosicion: "Exterior-Arriba",
            alcance: "exterior-pieza",
          },
          {
            id_trabajo_odon: "",
            trabajo: "",
            posicion: "abajo-pieza",
            nombrePosicion: "Exterior-Abajo",
            alcance: "exterior-pieza",
          },
        ],
      },
      {
        id: 25,
        nombre: "Segundo Premolar",
        posiciones: [
          {
            id_trabajo_odon: "",
            trabajo: "",
            posicion: "pieza",
            nombrePosicion: "Pieza",
            alcance: "pieza",
          },
          {
            id_trabajo_odon: "",
            trabajo: "",
            posicion: "centro",
            nombrePosicion: "Oclusal",
            alcance: "cara",
          },
          {
            id_trabajo_odon: "",
            trabajo: "",
            posicion: "izquierda",
            nombrePosicion: "Mesial",
            alcance: "cara",
          },
          {
            id_trabajo_odon: "",
            trabajo: "",
            posicion: "derecha",
            nombrePosicion: "Distal",
            alcance: "cara",
          },
          {
            id_trabajo_odon: "",
            trabajo: "",
            posicion: "arriba",
            nombrePosicion: "Vestibular",
            alcance: "cara",
          },
          {
            id_trabajo_odon: "",
            trabajo: "",
            posicion: "abajo",
            nombrePosicion: "Palatino",
            alcance: "cara",
          },
          {
            id_trabajo_odon: "",
            trabajo: "",
            posicion: "arriba-pieza",
            nombrePosicion: "Exterior-Arriba",
            alcance: "exterior-pieza",
          },
          {
            id_trabajo_odon: "",
            trabajo: "",
            posicion: "abajo-pieza",
            nombrePosicion: "Exterior-Abajo",
            alcance: "exterior-pieza",
          },
        ],
      },
      {
        id: 26,
        nombre: "Primer Molar",
        posiciones: [
          {
            id_trabajo_odon: "",
            trabajo: "",
            posicion: "pieza",
            nombrePosicion: "Pieza",
            alcance: "pieza",
          },
          {
            id_trabajo_odon: "",
            trabajo: "",
            posicion: "centro",
            nombrePosicion: "Oclusal",
            alcance: "cara",
          },
          {
            id_trabajo_odon: "",
            trabajo: "",
            posicion: "izquierda",
            nombrePosicion: "Mesial",
            alcance: "cara",
          },
          {
            id_trabajo_odon: "",
            trabajo: "",
            posicion: "derecha",
            nombrePosicion: "Distal",
            alcance: "cara",
          },
          {
            id_trabajo_odon: "",
            trabajo: "",
            posicion: "arriba",
            nombrePosicion: "Vestibular",
            alcance: "cara",
          },
          {
            id_trabajo_odon: "",
            trabajo: "",
            posicion: "abajo",
            nombrePosicion: "Palatino",
            alcance: "cara",
          },
          {
            id_trabajo_odon: "",
            trabajo: "",
            posicion: "arriba-pieza",
            nombrePosicion: "Exterior-Arriba",
            alcance: "exterior-pieza",
          },
          {
            id_trabajo_odon: "",
            trabajo: "",
            posicion: "abajo-pieza",
            nombrePosicion: "Exterior-Abajo",
            alcance: "exterior-pieza",
          },
        ],
      },
      {
        id: 27,
        nombre: "Segundo Molar",
        posiciones: [
          {
            id_trabajo_odon: "",
            trabajo: "",
            posicion: "pieza",
            nombrePosicion: "Pieza",
            alcance: "pieza",
          },
          {
            id_trabajo_odon: "",
            trabajo: "",
            posicion: "centro",
            nombrePosicion: "Oclusal",
            alcance: "cara",
          },
          {
            id_trabajo_odon: "",
            trabajo: "",
            posicion: "izquierda",
            nombrePosicion: "Mesial",
            alcance: "cara",
          },
          {
            id_trabajo_odon: "",
            trabajo: "",
            posicion: "derecha",
            nombrePosicion: "Distal",
            alcance: "cara",
          },
          {
            id_trabajo_odon: "",
            trabajo: "",
            posicion: "arriba",
            nombrePosicion: "Vestibular",
            alcance: "cara",
          },
          {
            id_trabajo_odon: "",
            trabajo: "",
            posicion: "abajo",
            nombrePosicion: "Palatino",
            alcance: "cara",
          },
          {
            id_trabajo_odon: "",
            trabajo: "",
            posicion: "arriba-pieza",
            nombrePosicion: "Exterior-Arriba",
            alcance: "exterior-pieza",
          },
          {
            id_trabajo_odon: "",
            trabajo: "",
            posicion: "abajo-pieza",
            nombrePosicion: "Exterior-Abajo",
            alcance: "exterior-pieza",
          },
        ],
      },
      {
        id: 28,
        nombre: "Tercer Molar",
        posiciones: [
          {
            id_trabajo_odon: "",
            trabajo: "",
            posicion: "pieza",
            nombrePosicion: "Pieza",
            alcance: "pieza",
          },
          {
            id_trabajo_odon: "",
            trabajo: "",
            posicion: "centro",
            nombrePosicion: "Oclusal",
            alcance: "cara",
          },
          {
            id_trabajo_odon: "",
            trabajo: "",
            posicion: "izquierda",
            nombrePosicion: "Mesial",
            alcance: "cara",
          },
          {
            id_trabajo_odon: "",
            trabajo: "",
            posicion: "derecha",
            nombrePosicion: "Distal",
            alcance: "cara",
          },
          {
            id_trabajo_odon: "",
            trabajo: "",
            posicion: "arriba",
            nombrePosicion: "Vestibular",
            alcance: "cara",
          },
          {
            id_trabajo_odon: "",
            trabajo: "",
            posicion: "abajo",
            nombrePosicion: "Palatino",
            alcance: "cara",
          },
          {
            id_trabajo_odon: "",
            trabajo: "",
            posicion: "arriba-pieza",
            nombrePosicion: "Exterior-Arriba",
            alcance: "exterior-pieza",
          },
          {
            id_trabajo_odon: "",
            trabajo: "",
            posicion: "abajo-pieza",
            nombrePosicion: "Exterior-Abajo",
            alcance: "exterior-pieza",
          },
        ],
      },
    ],
  },
  {
    cuadrante: 3,
    data: [
      {
        id: 48,
        nombre: "Tercer Molar",
        posiciones: [
          {
            id_trabajo_odon: "",
            trabajo: "",
            posicion: "pieza",
            nombrePosicion: "Pieza",
            alcance: "pieza",
          },
          {
            id_trabajo_odon: "",
            trabajo: "",
            posicion: "centro",
            nombrePosicion: "Oclusal",
            alcance: "cara",
          },
          {
            id_trabajo_odon: "",
            trabajo: "",
            posicion: "izquierda",
            nombrePosicion: "Distal",
            alcance: "cara",
          },
          {
            id_trabajo_odon: "",
            trabajo: "",
            posicion: "derecha",
            nombrePosicion: "Mesial",
            alcance: "cara",
          },
          {
            id_trabajo_odon: "",
            trabajo: "",
            posicion: "arriba",
            nombrePosicion: "Lingual",
            alcance: "cara",
          },
          {
            id_trabajo_odon: "",
            trabajo: "",
            posicion: "abajo",
            nombrePosicion: "Vestibular",
            alcance: "cara",
          },
          {
            id_trabajo_odon: "",
            trabajo: "",
            posicion: "arriba-pieza",
            nombrePosicion: "Exterior-Arriba",
            alcance: "exterior-pieza",
          },
          {
            id_trabajo_odon: "",
            trabajo: "",
            posicion: "abajo-pieza",
            nombrePosicion: "Exterior-Abajo",
            alcance: "exterior-pieza",
          },
        ],
      },
      {
        id: 47,
        nombre: "Segundo Molar",
        posiciones: [
          {
            id_trabajo_odon: "",
            trabajo: "",
            posicion: "pieza",
            nombrePosicion: "Pieza",
            alcance: "pieza",
          },
          {
            id_trabajo_odon: "",
            trabajo: "",
            posicion: "centro",
            nombrePosicion: "Oclusal",
            alcance: "cara",
          },
          {
            id_trabajo_odon: "",
            trabajo: "",
            posicion: "izquierda",
            nombrePosicion: "Distal",
            alcance: "cara",
          },
          {
            id_trabajo_odon: "",
            trabajo: "",
            posicion: "derecha",
            nombrePosicion: "Mesial",
            alcance: "cara",
          },
          {
            id_trabajo_odon: "",
            trabajo: "",
            posicion: "arriba",
            nombrePosicion: "Lingual",
            alcance: "cara",
          },
          {
            id_trabajo_odon: "",
            trabajo: "",
            posicion: "abajo",
            nombrePosicion: "Vestibular",
            alcance: "cara",
          },
          {
            id_trabajo_odon: "",
            trabajo: "",
            posicion: "arriba-pieza",
            nombrePosicion: "Exterior-Arriba",
            alcance: "exterior-pieza",
          },
          {
            id_trabajo_odon: "",
            trabajo: "",
            posicion: "abajo-pieza",
            nombrePosicion: "Exterior-Abajo",
            alcance: "exterior-pieza",
          },
        ],
      },
      {
        id: 46,
        nombre: "Primer Molar",
        posiciones: [
          {
            id_trabajo_odon: "",
            trabajo: "",
            posicion: "pieza",
            nombrePosicion: "Pieza",
            alcance: "pieza",
          },
          {
            id_trabajo_odon: "",
            trabajo: "",
            posicion: "centro",
            nombrePosicion: "Oclusal",
            alcance: "cara",
          },
          {
            id_trabajo_odon: "",
            trabajo: "",
            posicion: "izquierda",
            nombrePosicion: "Distal",
            alcance: "cara",
          },
          {
            id_trabajo_odon: "",
            trabajo: "",
            posicion: "derecha",
            nombrePosicion: "Mesial",
            alcance: "cara",
          },
          {
            id_trabajo_odon: "",
            trabajo: "",
            posicion: "arriba",
            nombrePosicion: "Lingual",
            alcance: "cara",
          },
          {
            id_trabajo_odon: "",
            trabajo: "",
            posicion: "abajo",
            nombrePosicion: "Vestibular",
            alcance: "cara",
          },
          {
            id_trabajo_odon: "",
            trabajo: "",
            posicion: "arriba-pieza",
            nombrePosicion: "Exterior-Arriba",
            alcance: "exterior-pieza",
          },
          {
            id_trabajo_odon: "",
            trabajo: "",
            posicion: "abajo-pieza",
            nombrePosicion: "Exterior-Abajo",
            alcance: "exterior-pieza",
          },
        ],
      },
      {
        id: 45,
        nombre: "Segundo Premolar",
        posiciones: [
          {
            id_trabajo_odon: "",
            trabajo: "",
            posicion: "pieza",
            nombrePosicion: "Pieza",
            alcance: "pieza",
          },
          {
            id_trabajo_odon: "",
            trabajo: "",
            posicion: "centro",
            nombrePosicion: "Oclusal",
            alcance: "cara",
          },
          {
            id_trabajo_odon: "",
            trabajo: "",
            posicion: "izquierda",
            nombrePosicion: "Distal",
            alcance: "cara",
          },
          {
            id_trabajo_odon: "",
            trabajo: "",
            posicion: "derecha",
            nombrePosicion: "Mesial",
            alcance: "cara",
          },
          {
            id_trabajo_odon: "",
            trabajo: "",
            posicion: "arriba",
            nombrePosicion: "Lingual",
            alcance: "cara",
          },
          {
            id_trabajo_odon: "",
            trabajo: "",
            posicion: "abajo",
            nombrePosicion: "Vestibular",
            alcance: "cara",
          },
          {
            id_trabajo_odon: "",
            trabajo: "",
            posicion: "arriba-pieza",
            nombrePosicion: "Exterior-Arriba",
            alcance: "exterior-pieza",
          },
          {
            id_trabajo_odon: "",
            trabajo: "",
            posicion: "abajo-pieza",
            nombrePosicion: "Exterior-Abajo",
            alcance: "exterior-pieza",
          },
        ],
      },
      {
        id: 44,
        nombre: "Primer Premolar",
        posiciones: [
          {
            id_trabajo_odon: "",
            trabajo: "",
            posicion: "pieza",
            nombrePosicion: "Pieza",
            alcance: "pieza",
          },
          {
            id_trabajo_odon: "",
            trabajo: "",
            posicion: "centro",
            nombrePosicion: "Oclusal",
            alcance: "cara",
          },
          {
            id_trabajo_odon: "",
            trabajo: "",
            posicion: "izquierda",
            nombrePosicion: "Distal",
            alcance: "cara",
          },
          {
            id_trabajo_odon: "",
            trabajo: "",
            posicion: "derecha",
            nombrePosicion: "Mesial",
            alcance: "cara",
          },
          {
            id_trabajo_odon: "",
            trabajo: "",
            posicion: "arriba",
            nombrePosicion: "Lingual",
            alcance: "cara",
          },
          {
            id_trabajo_odon: "",
            trabajo: "",
            posicion: "abajo",
            nombrePosicion: "Vestibular",
            alcance: "cara",
          },
          {
            id_trabajo_odon: "",
            trabajo: "",
            posicion: "arriba-pieza",
            nombrePosicion: "Exterior-Arriba",
            alcance: "exterior-pieza",
          },
          {
            id_trabajo_odon: "",
            trabajo: "",
            posicion: "abajo-pieza",
            nombrePosicion: "Exterior-Abajo",
            alcance: "exterior-pieza",
          },
        ],
      },
      {
        id: 43,
        nombre: "Canino",
        posiciones: [
          {
            id_trabajo_odon: "",
            trabajo: "",
            posicion: "pieza",
            nombrePosicion: "Pieza",
            alcance: "pieza",
          },
          {
            id_trabajo_odon: "",
            trabajo: "",
            posicion: "centro",
            nombrePosicion: "Incisal",
            alcance: "cara",
          },
          {
            id_trabajo_odon: "",
            trabajo: "",
            posicion: "izquierda",
            nombrePosicion: "Distal",
            alcance: "cara",
          },
          {
            id_trabajo_odon: "",
            trabajo: "",
            posicion: "derecha",
            nombrePosicion: "Mesial",
            alcance: "cara",
          },
          {
            id_trabajo_odon: "",
            trabajo: "",
            posicion: "arriba",
            nombrePosicion: "Lingual",
            alcance: "cara",
          },
          {
            id_trabajo_odon: "",
            trabajo: "",
            posicion: "abajo",
            nombrePosicion: "Vestibular",
            alcance: "cara",
          },
          {
            id_trabajo_odon: "",
            trabajo: "",
            posicion: "arriba-pieza",
            nombrePosicion: "Exterior-Arriba",
            alcance: "exterior-pieza",
          },
          {
            id_trabajo_odon: "",
            trabajo: "",
            posicion: "abajo-pieza",
            nombrePosicion: "Exterior-Abajo",
            alcance: "exterior-pieza",
          },
        ],
      },
      {
        id: 42,
        nombre: "Incisivo Lateral",
        posiciones: [
          {
            id_trabajo_odon: "",
            trabajo: "",
            posicion: "pieza",
            nombrePosicion: "Pieza",
            alcance: "pieza",
          },
          {
            id_trabajo_odon: "",
            trabajo: "",
            posicion: "centro",
            nombrePosicion: "Incisal",
            alcance: "cara",
          },
          {
            id_trabajo_odon: "",
            trabajo: "",
            posicion: "izquierda",
            nombrePosicion: "Distal",
            alcance: "cara",
          },
          {
            id_trabajo_odon: "",
            trabajo: "",
            posicion: "derecha",
            nombrePosicion: "Mesial",
            alcance: "cara",
          },
          {
            id_trabajo_odon: "",
            trabajo: "",
            posicion: "arriba",
            nombrePosicion: "Lingual",
            alcance: "cara",
          },
          {
            id_trabajo_odon: "",
            trabajo: "",
            posicion: "abajo",
            nombrePosicion: "Vestibular",
            alcance: "cara",
          },
          {
            id_trabajo_odon: "",
            trabajo: "",
            posicion: "arriba-pieza",
            nombrePosicion: "Exterior-Arriba",
            alcance: "exterior-pieza",
          },
          {
            id_trabajo_odon: "",
            trabajo: "",
            posicion: "abajo-pieza",
            nombrePosicion: "Exterior-Abajo",
            alcance: "exterior-pieza",
          },
        ],
      },
      {
        id: 41,
        nombre: "Incisivo Central",
        posiciones: [
          {
            id_trabajo_odon: "",
            trabajo: "",
            posicion: "pieza",
            nombrePosicion: "Pieza",
            alcance: "pieza",
          },
          {
            id_trabajo_odon: "",
            trabajo: "",
            posicion: "centro",
            nombrePosicion: "Incisal",
            alcance: "cara",
          },
          {
            id_trabajo_odon: "",
            trabajo: "",
            posicion: "izquierda",
            nombrePosicion: "Distal",
            alcance: "cara",
          },
          {
            id_trabajo_odon: "",
            trabajo: "",
            posicion: "derecha",
            nombrePosicion: "Mesial",
            alcance: "cara",
          },
          {
            id_trabajo_odon: "",
            trabajo: "",
            posicion: "arriba",
            nombrePosicion: "Lingual",
            alcance: "cara",
          },
          {
            id_trabajo_odon: "",
            trabajo: "",
            posicion: "abajo",
            nombrePosicion: "Vestibular",
            alcance: "cara",
          },
          {
            id_trabajo_odon: "",
            trabajo: "",
            posicion: "arriba-pieza",
            nombrePosicion: "Exterior-Arriba",
            alcance: "exterior-pieza",
          },
          {
            id_trabajo_odon: "",
            trabajo: "",
            posicion: "abajo-pieza",
            nombrePosicion: "Exterior-Abajo",
            alcance: "exterior-pieza",
          },
        ],
      },
    ],
  },
  {
    cuadrante: 4,
    data: [
      {
        id: 31,
        nombre: "Incisivo Central",
        posiciones: [
          {
            id_trabajo_odon: "",
            trabajo: "",
            posicion: "pieza",
            nombrePosicion: "Pieza",
            alcance: "pieza",
          },
          {
            id_trabajo_odon: "",
            trabajo: "",
            posicion: "centro",
            nombrePosicion: "Incisal",
            alcance: "cara",
          },
          {
            id_trabajo_odon: "",
            trabajo: "",
            posicion: "izquierda",
            nombrePosicion: "Mesial",
            alcance: "cara",
          },
          {
            id_trabajo_odon: "",
            trabajo: "",
            posicion: "derecha",
            nombrePosicion: "Distal",
            alcance: "cara",
          },
          {
            id_trabajo_odon: "",
            trabajo: "",
            posicion: "arriba",
            nombrePosicion: "Lingual",
            alcance: "cara",
          },
          {
            id_trabajo_odon: "",
            trabajo: "",
            posicion: "abajo",
            nombrePosicion: "Vestibular",
            alcance: "cara",
          },
          {
            id_trabajo_odon: "",
            trabajo: "",
            posicion: "arriba-pieza",
            nombrePosicion: "Exterior-Arriba",
            alcance: "exterior-pieza",
          },
          {
            id_trabajo_odon: "",
            trabajo: "",
            posicion: "abajo-pieza",
            nombrePosicion: "Exterior-Abajo",
            alcance: "exterior-pieza",
          },
        ],
      },
      {
        id: 32,
        nombre: "Incisivo Lateral",
        posiciones: [
          {
            id_trabajo_odon: "",
            trabajo: "",
            posicion: "pieza",
            nombrePosicion: "Pieza",
            alcance: "pieza",
          },
          {
            id_trabajo_odon: "",
            trabajo: "",
            posicion: "centro",
            nombrePosicion: "Incisal",
            alcance: "cara",
          },
          {
            id_trabajo_odon: "",
            trabajo: "",
            posicion: "izquierda",
            nombrePosicion: "Mesial",
            alcance: "cara",
          },
          {
            id_trabajo_odon: "",
            trabajo: "",
            posicion: "derecha",
            nombrePosicion: "Distal",
            alcance: "cara",
          },
          {
            id_trabajo_odon: "",
            trabajo: "",
            posicion: "arriba",
            nombrePosicion: "Lingual",
            alcance: "cara",
          },
          {
            id_trabajo_odon: "",
            trabajo: "",
            posicion: "abajo",
            nombrePosicion: "Vestibular",
            alcance: "cara",
          },
          {
            id_trabajo_odon: "",
            trabajo: "",
            posicion: "arriba-pieza",
            nombrePosicion: "Exterior-Arriba",
            alcance: "exterior-pieza",
          },
          {
            id_trabajo_odon: "",
            trabajo: "",
            posicion: "abajo-pieza",
            nombrePosicion: "Exterior-Abajo",
            alcance: "exterior-pieza",
          },
        ],
      },
      {
        id: 33,
        nombre: "Canino",
        posiciones: [
          {
            id_trabajo_odon: "",
            trabajo: "",
            posicion: "pieza",
            nombrePosicion: "Pieza",
            alcance: "pieza",
          },
          {
            id_trabajo_odon: "",
            trabajo: "",
            posicion: "centro",
            nombrePosicion: "Incisal",
            alcance: "cara",
          },
          {
            id_trabajo_odon: "",
            trabajo: "",
            posicion: "izquierda",
            nombrePosicion: "Mesial",
            alcance: "cara",
          },
          {
            id_trabajo_odon: "",
            trabajo: "",
            posicion: "derecha",
            nombrePosicion: "Distal",
            alcance: "cara",
          },
          {
            id_trabajo_odon: "",
            trabajo: "",
            posicion: "arriba",
            nombrePosicion: "Lingual",
            alcance: "cara",
          },
          {
            id_trabajo_odon: "",
            trabajo: "",
            posicion: "abajo",
            nombrePosicion: "Vestibular",
            alcance: "cara",
          },
          {
            id_trabajo_odon: "",
            trabajo: "",
            posicion: "arriba-pieza",
            nombrePosicion: "Exterior-Arriba",
            alcance: "exterior-pieza",
          },
          {
            id_trabajo_odon: "",
            trabajo: "",
            posicion: "abajo-pieza",
            nombrePosicion: "Exterior-Abajo",
            alcance: "exterior-pieza",
          },
        ],
      },
      {
        id: 34,
        nombre: "Primer Premolar",
        posiciones: [
          {
            id_trabajo_odon: "",
            trabajo: "",
            posicion: "pieza",
            nombrePosicion: "Pieza",
            alcance: "pieza",
          },
          {
            id_trabajo_odon: "",
            trabajo: "",
            posicion: "centro",
            nombrePosicion: "Oclusal",
            alcance: "cara",
          },
          {
            id_trabajo_odon: "",
            trabajo: "",
            posicion: "izquierda",
            nombrePosicion: "Mesial",
            alcance: "cara",
          },
          {
            id_trabajo_odon: "",
            trabajo: "",
            posicion: "derecha",
            nombrePosicion: "Distal",
            alcance: "cara",
          },
          {
            id_trabajo_odon: "",
            trabajo: "",
            posicion: "arriba",
            nombrePosicion: "Lingual",
            alcance: "cara",
          },
          {
            id_trabajo_odon: "",
            trabajo: "",
            posicion: "abajo",
            nombrePosicion: "Vestibular",
            alcance: "cara",
          },
          {
            id_trabajo_odon: "",
            trabajo: "",
            posicion: "arriba-pieza",
            nombrePosicion: "Exterior-Arriba",
            alcance: "exterior-pieza",
          },
          {
            id_trabajo_odon: "",
            trabajo: "",
            posicion: "abajo-pieza",
            nombrePosicion: "Exterior-Abajo",
            alcance: "exterior-pieza",
          },
        ],
      },
      {
        id: 35,
        nombre: "Segundo Premolar",
        posiciones: [
          {
            id_trabajo_odon: "",
            trabajo: "",
            posicion: "pieza",
            nombrePosicion: "Pieza",
            alcance: "pieza",
          },
          {
            id_trabajo_odon: "",
            trabajo: "",
            posicion: "centro",
            nombrePosicion: "Oclusal",
            alcance: "cara",
          },
          {
            id_trabajo_odon: "",
            trabajo: "",
            posicion: "izquierda",
            nombrePosicion: "Mesial",
            alcance: "cara",
          },
          {
            id_trabajo_odon: "",
            trabajo: "",
            posicion: "derecha",
            nombrePosicion: "Distal",
            alcance: "cara",
          },
          {
            id_trabajo_odon: "",
            trabajo: "",
            posicion: "arriba",
            nombrePosicion: "Lingual",
            alcance: "cara",
          },
          {
            id_trabajo_odon: "",
            trabajo: "",
            posicion: "abajo",
            nombrePosicion: "Vestibular",
            alcance: "cara",
          },
          {
            id_trabajo_odon: "",
            trabajo: "",
            posicion: "arriba-pieza",
            nombrePosicion: "Exterior-Arriba",
            alcance: "exterior-pieza",
          },
          {
            id_trabajo_odon: "",
            trabajo: "",
            posicion: "abajo-pieza",
            nombrePosicion: "Exterior-Abajo",
            alcance: "exterior-pieza",
          },
        ],
      },
      {
        id: 36,
        nombre: "Primer Molar",
        posiciones: [
          {
            id_trabajo_odon: "",
            trabajo: "",
            posicion: "pieza",
            nombrePosicion: "Pieza",
            alcance: "pieza",
          },
          {
            id_trabajo_odon: "",
            trabajo: "",
            posicion: "centro",
            nombrePosicion: "Oclusal",
            alcance: "cara",
          },
          {
            id_trabajo_odon: "",
            trabajo: "",
            posicion: "izquierda",
            nombrePosicion: "Mesial",
            alcance: "cara",
          },
          {
            id_trabajo_odon: "",
            trabajo: "",
            posicion: "derecha",
            nombrePosicion: "Distal",
            alcance: "cara",
          },
          {
            id_trabajo_odon: "",
            trabajo: "",
            posicion: "arriba",
            nombrePosicion: "Lingual",
            alcance: "cara",
          },
          {
            id_trabajo_odon: "",
            trabajo: "",
            posicion: "abajo",
            nombrePosicion: "Vestibular",
            alcance: "cara",
          },
          {
            id_trabajo_odon: "",
            trabajo: "",
            posicion: "arriba-pieza",
            nombrePosicion: "Exterior-Arriba",
            alcance: "exterior-pieza",
          },
          {
            id_trabajo_odon: "",
            trabajo: "",
            posicion: "abajo-pieza",
            nombrePosicion: "Exterior-Abajo",
            alcance: "exterior-pieza",
          },
        ],
      },
      {
        id: 37,
        nombre: "Segundo Molar",
        posiciones: [
          {
            id_trabajo_odon: "",
            trabajo: "",
            posicion: "pieza",
            nombrePosicion: "Pieza",
            alcance: "pieza",
          },
          {
            id_trabajo_odon: "",
            trabajo: "",
            posicion: "centro",
            nombrePosicion: "Oclusal",
            alcance: "cara",
          },
          {
            id_trabajo_odon: "",
            trabajo: "",
            posicion: "izquierda",
            nombrePosicion: "Mesial",
            alcance: "cara",
          },
          {
            id_trabajo_odon: "",
            trabajo: "",
            posicion: "derecha",
            nombrePosicion: "Distal",
            alcance: "cara",
          },
          {
            id_trabajo_odon: "",
            trabajo: "",
            posicion: "arriba",
            nombrePosicion: "Lingual",
            alcance: "cara",
          },
          {
            id_trabajo_odon: "",
            trabajo: "",
            posicion: "abajo",
            nombrePosicion: "Vestibular",
            alcance: "cara",
          },
          {
            id_trabajo_odon: "",
            trabajo: "",
            posicion: "arriba-pieza",
            nombrePosicion: "Exterior-Arriba",
            alcance: "exterior-pieza",
          },
          {
            id_trabajo_odon: "",
            trabajo: "",
            posicion: "abajo-pieza",
            nombrePosicion: "Exterior-Abajo",
            alcance: "exterior-pieza",
          },
        ],
      },
      {
        id: 38,
        nombre: "Tercer Molar",
        posiciones: [
          {
            id_trabajo_odon: "",
            trabajo: "",
            posicion: "pieza",
            nombrePosicion: "Pieza",
            alcance: "pieza",
          },
          {
            id_trabajo_odon: "",
            trabajo: "",
            posicion: "centro",
            nombrePosicion: "Oclusal",
            alcance: "cara",
          },
          {
            id_trabajo_odon: "",
            trabajo: "",
            posicion: "izquierda",
            nombrePosicion: "Mesial",
            alcance: "cara",
          },
          {
            id_trabajo_odon: "",
            trabajo: "",
            posicion: "derecha",
            nombrePosicion: "Distal",
            alcance: "cara",
          },
          {
            id_trabajo_odon: "",
            trabajo: "",
            posicion: "arriba",
            nombrePosicion: "Lingual",
            alcance: "cara",
          },
          {
            id_trabajo_odon: "",
            trabajo: "",
            posicion: "abajo",
            nombrePosicion: "Vestibular",
            alcance: "cara",
          },
          {
            id_trabajo_odon: "",
            trabajo: "",
            posicion: "arriba-pieza",
            nombrePosicion: "Exterior-Arriba",
            alcance: "exterior-pieza",
          },
          {
            id_trabajo_odon: "",
            trabajo: "",
            posicion: "abajo-pieza",
            nombrePosicion: "Exterior-Abajo",
            alcance: "exterior-pieza",
          },
        ],
      },
    ],
  },
];

export let bocaCompletaTemporales = [
  {
    cuadrante: 1,
    data: [
      {
        id: 55,
        nombre: "Segundo Premolar",
        posiciones: [
          {
            id_trabajo_odon: "",
            trabajo: "",
            posicion: "pieza",
            nombrePosicion: "Pieza",
            alcance: "pieza",
          },
          {
            id_trabajo_odon: "",
            trabajo: "",
            posicion: "centro",
            nombrePosicion: "Oclusal",
            alcance: "cara",
          },
          {
            id_trabajo_odon: "",
            trabajo: "",
            posicion: "izquierda",
            nombrePosicion: "Distal",
            alcance: "cara",
          },
          {
            id_trabajo_odon: "",
            trabajo: "",
            posicion: "derecha",
            nombrePosicion: "Mesial",
            alcance: "cara",
          },
          {
            id_trabajo_odon: "",
            trabajo: "",
            posicion: "arriba",
            nombrePosicion: "Vestibular",
            alcance: "cara",
          },
          {
            id_trabajo_odon: "",
            trabajo: "",
            posicion: "abajo",
            nombrePosicion: "Palatino",
            alcance: "cara",
          },
          {
            id_trabajo_odon: "",
            trabajo: "",
            posicion: "arriba-pieza",
            nombrePosicion: "Exterior-Arriba",
            alcance: "exterior-pieza",
          },
          {
            id_trabajo_odon: "",
            trabajo: "",
            posicion: "abajo-pieza",
            nombrePosicion: "Exterior-Abajo",
            alcance: "exterior-pieza",
          },
        ],
      },
      {
        id: 54,
        nombre: "Primer Premolar",
        posiciones: [
          {
            id_trabajo_odon: "",
            trabajo: "",
            posicion: "pieza",
            nombrePosicion: "Pieza",
            alcance: "pieza",
          },
          {
            id_trabajo_odon: "",
            trabajo: "",
            posicion: "centro",
            nombrePosicion: "Oclusal",
            alcance: "cara",
          },
          {
            id_trabajo_odon: "",
            trabajo: "",
            posicion: "izquierda",
            nombrePosicion: "Distal",
            alcance: "cara",
          },
          {
            id_trabajo_odon: "",
            trabajo: "",
            posicion: "derecha",
            nombrePosicion: "Mesial",
            alcance: "cara",
          },
          {
            id_trabajo_odon: "",
            trabajo: "",
            posicion: "arriba",
            nombrePosicion: "Vestibular",
            alcance: "cara",
          },
          {
            id_trabajo_odon: "",
            trabajo: "",
            posicion: "abajo",
            nombrePosicion: "Palatino",
            alcance: "cara",
          },
          {
            id_trabajo_odon: "",
            trabajo: "",
            posicion: "arriba-pieza",
            nombrePosicion: "Exterior-Arriba",
            alcance: "exterior-pieza",
          },
          {
            id_trabajo_odon: "",
            trabajo: "",
            posicion: "abajo-pieza",
            nombrePosicion: "Exterior-Abajo",
            alcance: "exterior-pieza",
          },
        ],
      },
      {
        id: 53,
        nombre: "Canino",
        posiciones: [
          {
            id_trabajo_odon: "",
            trabajo: "",
            posicion: "pieza",
            nombrePosicion: "Pieza",
            alcance: "pieza",
          },
          {
            id_trabajo_odon: "",
            trabajo: "",
            posicion: "centro",
            nombrePosicion: "Incisal",
            alcance: "cara",
          },
          {
            id_trabajo_odon: "",
            trabajo: "",
            posicion: "izquierda",
            nombrePosicion: "Distal",
            alcance: "cara",
          },
          {
            id_trabajo_odon: "",
            trabajo: "",
            posicion: "derecha",
            nombrePosicion: "Mesial",
            alcance: "cara",
          },
          {
            id_trabajo_odon: "",
            trabajo: "",
            posicion: "arriba",
            nombrePosicion: "Vestibular",
            alcance: "cara",
          },
          {
            id_trabajo_odon: "",
            trabajo: "",
            posicion: "abajo",
            nombrePosicion: "Palatino",
            alcance: "cara",
          },
          {
            id_trabajo_odon: "",
            trabajo: "",
            posicion: "arriba-pieza",
            nombrePosicion: "Exterior-Arriba",
            alcance: "exterior-pieza",
          },
          {
            id_trabajo_odon: "",
            trabajo: "",
            posicion: "abajo-pieza",
            nombrePosicion: "Exterior-Abajo",
            alcance: "exterior-pieza",
          },
        ],
      },
      {
        id: 52,
        nombre: "Incisivo Lateral",
        posiciones: [
          {
            id_trabajo_odon: "",
            trabajo: "",
            posicion: "pieza",
            nombrePosicion: "Pieza",
            alcance: "pieza",
          },
          {
            id_trabajo_odon: "",
            trabajo: "",
            posicion: "centro",
            nombrePosicion: "Incisal",
            alcance: "cara",
          },
          {
            id_trabajo_odon: "",
            trabajo: "",
            posicion: "izquierda",
            nombrePosicion: "Distal",
            alcance: "cara",
          },
          {
            id_trabajo_odon: "",
            trabajo: "",
            posicion: "derecha",
            nombrePosicion: "Mesial",
            alcance: "cara",
          },
          {
            id_trabajo_odon: "",
            trabajo: "",
            posicion: "arriba",
            nombrePosicion: "Vestibular",
            alcance: "cara",
          },
          {
            id_trabajo_odon: "",
            trabajo: "",
            posicion: "abajo",
            nombrePosicion: "Palatino",
            alcance: "cara",
          },
          {
            id_trabajo_odon: "",
            trabajo: "",
            posicion: "arriba-pieza",
            nombrePosicion: "Exterior-Arriba",
            alcance: "exterior-pieza",
          },
          {
            id_trabajo_odon: "",
            trabajo: "",
            posicion: "abajo-pieza",
            nombrePosicion: "Exterior-Abajo",
            alcance: "exterior-pieza",
          },
        ],
      },
      {
        id: 51,
        nombre: "Incisivo Central",
        posiciones: [
          {
            id_trabajo_odon: "",
            trabajo: "",
            posicion: "pieza",
            nombrePosicion: "Pieza",
            alcance: "pieza",
          },
          {
            id_trabajo_odon: "",
            trabajo: "",
            posicion: "centro",
            nombrePosicion: "Incisal",
            alcance: "cara",
          },
          {
            id_trabajo_odon: "",
            trabajo: "",
            posicion: "izquierda",
            nombrePosicion: "Distal",
            alcance: "cara",
          },
          {
            id_trabajo_odon: "",
            trabajo: "",
            posicion: "derecha",
            nombrePosicion: "Mesial",
            alcance: "cara",
          },
          {
            id_trabajo_odon: "",
            trabajo: "",
            posicion: "arriba",
            nombrePosicion: "Vestibular",
            alcance: "cara",
          },
          {
            id_trabajo_odon: "",
            trabajo: "",
            posicion: "abajo",
            nombrePosicion: "Palatino",
            alcance: "cara",
          },
          {
            id_trabajo_odon: "",
            trabajo: "",
            posicion: "arriba-pieza",
            nombrePosicion: "Exterior-Arriba",
            alcance: "exterior-pieza",
          },
          {
            id_trabajo_odon: "",
            trabajo: "",
            posicion: "abajo-pieza",
            nombrePosicion: "Exterior-Abajo",
            alcance: "exterior-pieza",
          },
        ],
      },
    ],
  },
  {
    cuadrante: 2,
    data: [
      {
        id: 61,
        nombre: "Incisivo Central",
        posiciones: [
          {
            id_trabajo_odon: "",
            trabajo: "",
            posicion: "pieza",
            nombrePosicion: "Pieza",
            alcance: "pieza",
          },
          {
            id_trabajo_odon: "",
            trabajo: "",
            posicion: "centro",
            nombrePosicion: "Incisal",
            alcance: "cara",
          },
          {
            id_trabajo_odon: "",
            trabajo: "",
            posicion: "izquierda",
            nombrePosicion: "Mesial",
            alcance: "cara",
          },
          {
            id_trabajo_odon: "",
            trabajo: "",
            posicion: "derecha",
            nombrePosicion: "Distal",
            alcance: "cara",
          },
          {
            id_trabajo_odon: "",
            trabajo: "",
            posicion: "arriba",
            nombrePosicion: "Vestibular",
            alcance: "cara",
          },
          {
            id_trabajo_odon: "",
            trabajo: "",
            posicion: "abajo",
            nombrePosicion: "Palatino",
            alcance: "cara",
          },
          {
            id_trabajo_odon: "",
            trabajo: "",
            posicion: "arriba-pieza",
            nombrePosicion: "Exterior-Arriba",
            alcance: "exterior-pieza",
          },
          {
            id_trabajo_odon: "",
            trabajo: "",
            posicion: "abajo-pieza",
            nombrePosicion: "Exterior-Abajo",
            alcance: "exterior-pieza",
          },
        ],
      },
      {
        id: 62,
        nombre: "Incisivo Lateral",
        posiciones: [
          {
            id_trabajo_odon: "",
            trabajo: "",
            posicion: "pieza",
            nombrePosicion: "Pieza",
            alcance: "pieza",
          },
          {
            id_trabajo_odon: "",
            trabajo: "",
            posicion: "centro",
            nombrePosicion: "Incisal",
            alcance: "cara",
          },
          {
            id_trabajo_odon: "",
            trabajo: "",
            posicion: "izquierda",
            nombrePosicion: "Mesial",
            alcance: "cara",
          },
          {
            id_trabajo_odon: "",
            trabajo: "",
            posicion: "derecha",
            nombrePosicion: "Distal",
            alcance: "cara",
          },
          {
            id_trabajo_odon: "",
            trabajo: "",
            posicion: "arriba",
            nombrePosicion: "Vestibular",
            alcance: "cara",
          },
          {
            id_trabajo_odon: "",
            trabajo: "",
            posicion: "abajo",
            nombrePosicion: "Palatino",
            alcance: "cara",
          },
          {
            id_trabajo_odon: "",
            trabajo: "",
            posicion: "arriba-pieza",
            nombrePosicion: "Exterior-Arriba",
            alcance: "exterior-pieza",
          },
          {
            id_trabajo_odon: "",
            trabajo: "",
            posicion: "abajo-pieza",
            nombrePosicion: "Exterior-Abajo",
            alcance: "exterior-pieza",
          },
        ],
      },
      {
        id: 63,
        nombre: "Canino",
        posiciones: [
          {
            id_trabajo_odon: "",
            trabajo: "",
            posicion: "pieza",
            nombrePosicion: "Pieza",
            alcance: "pieza",
          },
          {
            id_trabajo_odon: "",
            trabajo: "",
            posicion: "centro",
            nombrePosicion: "Incisal",
            alcance: "cara",
          },
          {
            id_trabajo_odon: "",
            trabajo: "",
            posicion: "izquierda",
            nombrePosicion: "Mesial",
            alcance: "cara",
          },
          {
            id_trabajo_odon: "",
            trabajo: "",
            posicion: "derecha",
            nombrePosicion: "Distal",
            alcance: "cara",
          },
          {
            id_trabajo_odon: "",
            trabajo: "",
            posicion: "arriba",
            nombrePosicion: "Vestibular",
            alcance: "cara",
          },
          {
            id_trabajo_odon: "",
            trabajo: "",
            posicion: "abajo",
            nombrePosicion: "Palatino",
            alcance: "cara",
          },
          {
            id_trabajo_odon: "",
            trabajo: "",
            posicion: "arriba-pieza",
            nombrePosicion: "Exterior-Arriba",
            alcance: "exterior-pieza",
          },
          {
            id_trabajo_odon: "",
            trabajo: "",
            posicion: "abajo-pieza",
            nombrePosicion: "Exterior-Abajo",
            alcance: "exterior-pieza",
          },
        ],
      },
      {
        id: 64,
        nombre: "Primer Premolar",
        posiciones: [
          {
            id_trabajo_odon: "",
            trabajo: "",
            posicion: "pieza",
            nombrePosicion: "Pieza",
            alcance: "pieza",
          },
          {
            id_trabajo_odon: "",
            trabajo: "",
            posicion: "centro",
            nombrePosicion: "Oclusal",
            alcance: "cara",
          },
          {
            id_trabajo_odon: "",
            trabajo: "",
            posicion: "izquierda",
            nombrePosicion: "Mesial",
            alcance: "cara",
          },
          {
            id_trabajo_odon: "",
            trabajo: "",
            posicion: "derecha",
            nombrePosicion: "Distal",
            alcance: "cara",
          },
          {
            id_trabajo_odon: "",
            trabajo: "",
            posicion: "arriba",
            nombrePosicion: "Vestibular",
            alcance: "cara",
          },
          {
            id_trabajo_odon: "",
            trabajo: "",
            posicion: "abajo",
            nombrePosicion: "Palatino",
            alcance: "cara",
          },
          {
            id_trabajo_odon: "",
            trabajo: "",
            posicion: "arriba-pieza",
            nombrePosicion: "Exterior-Arriba",
            alcance: "exterior-pieza",
          },
          {
            id_trabajo_odon: "",
            trabajo: "",
            posicion: "abajo-pieza",
            nombrePosicion: "Exterior-Abajo",
            alcance: "exterior-pieza",
          },
        ],
      },
      {
        id: 65,
        nombre: "Segundo Premolar",
        posiciones: [
          {
            id_trabajo_odon: "",
            trabajo: "",
            posicion: "pieza",
            nombrePosicion: "Pieza",
            alcance: "pieza",
          },
          {
            id_trabajo_odon: "",
            trabajo: "",
            posicion: "centro",
            nombrePosicion: "Oclusal",
            alcance: "cara",
          },
          {
            id_trabajo_odon: "",
            trabajo: "",
            posicion: "izquierda",
            nombrePosicion: "Mesial",
            alcance: "cara",
          },
          {
            id_trabajo_odon: "",
            trabajo: "",
            posicion: "derecha",
            nombrePosicion: "Distal",
            alcance: "cara",
          },
          {
            id_trabajo_odon: "",
            trabajo: "",
            posicion: "arriba",
            nombrePosicion: "Vestibular",
            alcance: "cara",
          },
          {
            id_trabajo_odon: "",
            trabajo: "",
            posicion: "abajo",
            nombrePosicion: "Palatino",
            alcance: "cara",
          },
          {
            id_trabajo_odon: "",
            trabajo: "",
            posicion: "arriba-pieza",
            nombrePosicion: "Exterior-Arriba",
            alcance: "exterior-pieza",
          },
          {
            id_trabajo_odon: "",
            trabajo: "",
            posicion: "abajo-pieza",
            nombrePosicion: "Exterior-Abajo",
            alcance: "exterior-pieza",
          },
        ],
      },
    ],
  },
  {
    cuadrante: 3,
    data: [
      {
        id: 85,
        nombre: "Segundo Premolar",
        posiciones: [
          {
            id_trabajo_odon: "",
            trabajo: "",
            posicion: "pieza",
            nombrePosicion: "Pieza",
            alcance: "pieza",
          },
          {
            id_trabajo_odon: "",
            trabajo: "",
            posicion: "centro",
            nombrePosicion: "Oclusal",
            alcance: "cara",
          },
          {
            id_trabajo_odon: "",
            trabajo: "",
            posicion: "izquierda",
            nombrePosicion: "Distal",
            alcance: "cara",
          },
          {
            id_trabajo_odon: "",
            trabajo: "",
            posicion: "derecha",
            nombrePosicion: "Mesial",
            alcance: "cara",
          },
          {
            id_trabajo_odon: "",
            trabajo: "",
            posicion: "arriba",
            nombrePosicion: "Lingual",
            alcance: "cara",
          },
          {
            id_trabajo_odon: "",
            trabajo: "",
            posicion: "abajo",
            nombrePosicion: "Vestibular",
            alcance: "cara",
          },
          {
            id_trabajo_odon: "",
            trabajo: "",
            posicion: "arriba-pieza",
            nombrePosicion: "Exterior-Arriba",
            alcance: "exterior-pieza",
          },
          {
            id_trabajo_odon: "",
            trabajo: "",
            posicion: "abajo-pieza",
            nombrePosicion: "Exterior-Abajo",
            alcance: "exterior-pieza",
          },
        ],
      },
      {
        id: 84,
        nombre: "Primer Premolar",
        posiciones: [
          {
            id_trabajo_odon: "",
            trabajo: "",
            posicion: "pieza",
            nombrePosicion: "Pieza",
            alcance: "pieza",
          },
          {
            id_trabajo_odon: "",
            trabajo: "",
            posicion: "centro",
            nombrePosicion: "Oclusal",
            alcance: "cara",
          },
          {
            id_trabajo_odon: "",
            trabajo: "",
            posicion: "izquierda",
            nombrePosicion: "Distal",
            alcance: "cara",
          },
          {
            id_trabajo_odon: "",
            trabajo: "",
            posicion: "derecha",
            nombrePosicion: "Mesial",
            alcance: "cara",
          },
          {
            id_trabajo_odon: "",
            trabajo: "",
            posicion: "arriba",
            nombrePosicion: "Lingual",
            alcance: "cara",
          },
          {
            id_trabajo_odon: "",
            trabajo: "",
            posicion: "abajo",
            nombrePosicion: "Vestibular",
            alcance: "cara",
          },
          {
            id_trabajo_odon: "",
            trabajo: "",
            posicion: "arriba-pieza",
            nombrePosicion: "Exterior-Arriba",
            alcance: "exterior-pieza",
          },
          {
            id_trabajo_odon: "",
            trabajo: "",
            posicion: "abajo-pieza",
            nombrePosicion: "Exterior-Abajo",
            alcance: "exterior-pieza",
          },
        ],
      },
      {
        id: 83,
        nombre: "Canino",
        posiciones: [
          {
            id_trabajo_odon: "",
            trabajo: "",
            posicion: "pieza",
            nombrePosicion: "Pieza",
            alcance: "pieza",
          },
          {
            id_trabajo_odon: "",
            trabajo: "",
            posicion: "centro",
            nombrePosicion: "Incisal",
            alcance: "cara",
          },
          {
            id_trabajo_odon: "",
            trabajo: "",
            posicion: "izquierda",
            nombrePosicion: "Distal",
            alcance: "cara",
          },
          {
            id_trabajo_odon: "",
            trabajo: "",
            posicion: "derecha",
            nombrePosicion: "Mesial",
            alcance: "cara",
          },
          {
            id_trabajo_odon: "",
            trabajo: "",
            posicion: "arriba",
            nombrePosicion: "Lingual",
            alcance: "cara",
          },
          {
            id_trabajo_odon: "",
            trabajo: "",
            posicion: "abajo",
            nombrePosicion: "Vestibular",
            alcance: "cara",
          },
          {
            id_trabajo_odon: "",
            trabajo: "",
            posicion: "arriba-pieza",
            nombrePosicion: "Exterior-Arriba",
            alcance: "exterior-pieza",
          },
          {
            id_trabajo_odon: "",
            trabajo: "",
            posicion: "abajo-pieza",
            nombrePosicion: "Exterior-Abajo",
            alcance: "exterior-pieza",
          },
        ],
      },
      {
        id: 82,
        nombre: "Incisivo Lateral",
        posiciones: [
          {
            id_trabajo_odon: "",
            trabajo: "",
            posicion: "pieza",
            nombrePosicion: "Pieza",
            alcance: "pieza",
          },
          {
            id_trabajo_odon: "",
            trabajo: "",
            posicion: "centro",
            nombrePosicion: "Incisal",
            alcance: "cara",
          },
          {
            id_trabajo_odon: "",
            trabajo: "",
            posicion: "izquierda",
            nombrePosicion: "Distal",
            alcance: "cara",
          },
          {
            id_trabajo_odon: "",
            trabajo: "",
            posicion: "derecha",
            nombrePosicion: "Mesial",
            alcance: "cara",
          },
          {
            id_trabajo_odon: "",
            trabajo: "",
            posicion: "arriba",
            nombrePosicion: "Lingual",
            alcance: "cara",
          },
          {
            id_trabajo_odon: "",
            trabajo: "",
            posicion: "abajo",
            nombrePosicion: "Vestibular",
            alcance: "cara",
          },
          {
            id_trabajo_odon: "",
            trabajo: "",
            posicion: "arriba-pieza",
            nombrePosicion: "Exterior-Arriba",
            alcance: "exterior-pieza",
          },
          {
            id_trabajo_odon: "",
            trabajo: "",
            posicion: "abajo-pieza",
            nombrePosicion: "Exterior-Abajo",
            alcance: "exterior-pieza",
          },
        ],
      },
      {
        id: 81,
        nombre: "Incisivo Central",
        posiciones: [
          {
            id_trabajo_odon: "",
            trabajo: "",
            posicion: "pieza",
            nombrePosicion: "Pieza",
            alcance: "pieza",
          },
          {
            id_trabajo_odon: "",
            trabajo: "",
            posicion: "centro",
            nombrePosicion: "Incisal",
            alcance: "cara",
          },
          {
            id_trabajo_odon: "",
            trabajo: "",
            posicion: "izquierda",
            nombrePosicion: "Distal",
            alcance: "cara",
          },
          {
            id_trabajo_odon: "",
            trabajo: "",
            posicion: "derecha",
            nombrePosicion: "Mesial",
            alcance: "cara",
          },
          {
            id_trabajo_odon: "",
            trabajo: "",
            posicion: "arriba",
            nombrePosicion: "Lingual",
            alcance: "cara",
          },
          {
            id_trabajo_odon: "",
            trabajo: "",
            posicion: "abajo",
            nombrePosicion: "Vestibular",
            alcance: "cara",
          },
          {
            id_trabajo_odon: "",
            trabajo: "",
            posicion: "arriba-pieza",
            nombrePosicion: "Exterior-Arriba",
            alcance: "exterior-pieza",
          },
          {
            id_trabajo_odon: "",
            trabajo: "",
            posicion: "abajo-pieza",
            nombrePosicion: "Exterior-Abajo",
            alcance: "exterior-pieza",
          },
        ],
      },
    ],
  },
  {
    cuadrante: 4,
    data: [
      {
        id: 71,
        nombre: "Incisivo Central",
        posiciones: [
          {
            id_trabajo_odon: "",
            trabajo: "",
            posicion: "pieza",
            nombrePosicion: "Pieza",
            alcance: "pieza",
          },
          {
            id_trabajo_odon: "",
            trabajo: "",
            posicion: "centro",
            nombrePosicion: "Incisal",
            alcance: "cara",
          },
          {
            id_trabajo_odon: "",
            trabajo: "",
            posicion: "izquierda",
            nombrePosicion: "Mesial",
            alcance: "cara",
          },
          {
            id_trabajo_odon: "",
            trabajo: "",
            posicion: "derecha",
            nombrePosicion: "Distal",
            alcance: "cara",
          },
          {
            id_trabajo_odon: "",
            trabajo: "",
            posicion: "arriba",
            nombrePosicion: "Lingual",
            alcance: "cara",
          },
          {
            id_trabajo_odon: "",
            trabajo: "",
            posicion: "abajo",
            nombrePosicion: "Vestibular",
            alcance: "cara",
          },
          {
            id_trabajo_odon: "",
            trabajo: "",
            posicion: "arriba-pieza",
            nombrePosicion: "Exterior-Arriba",
            alcance: "exterior-pieza",
          },
          {
            id_trabajo_odon: "",
            trabajo: "",
            posicion: "abajo-pieza",
            nombrePosicion: "Exterior-Abajo",
            alcance: "exterior-pieza",
          },
        ],
      },
      {
        id: 72,
        nombre: "Incisivo Lateral",
        posiciones: [
          {
            id_trabajo_odon: "",
            trabajo: "",
            posicion: "pieza",
            nombrePosicion: "Pieza",
            alcance: "pieza",
          },
          {
            id_trabajo_odon: "",
            trabajo: "",
            posicion: "centro",
            nombrePosicion: "Incisal",
            alcance: "cara",
          },
          {
            id_trabajo_odon: "",
            trabajo: "",
            posicion: "izquierda",
            nombrePosicion: "Mesial",
            alcance: "cara",
          },
          {
            id_trabajo_odon: "",
            trabajo: "",
            posicion: "derecha",
            nombrePosicion: "Distal",
            alcance: "cara",
          },
          {
            id_trabajo_odon: "",
            trabajo: "",
            posicion: "arriba",
            nombrePosicion: "Lingual",
            alcance: "cara",
          },
          {
            id_trabajo_odon: "",
            trabajo: "",
            posicion: "abajo",
            nombrePosicion: "Vestibular",
            alcance: "cara",
          },
          {
            id_trabajo_odon: "",
            trabajo: "",
            posicion: "arriba-pieza",
            nombrePosicion: "Exterior-Arriba",
            alcance: "exterior-pieza",
          },
          {
            id_trabajo_odon: "",
            trabajo: "",
            posicion: "abajo-pieza",
            nombrePosicion: "Exterior-Abajo",
            alcance: "exterior-pieza",
          },
        ],
      },
      {
        id: 73,
        nombre: "Canino",
        posiciones: [
          {
            id_trabajo_odon: "",
            trabajo: "",
            posicion: "pieza",
            nombrePosicion: "Pieza",
            alcance: "pieza",
          },
          {
            id_trabajo_odon: "",
            trabajo: "",
            posicion: "centro",
            nombrePosicion: "Incisal",
            alcance: "cara",
          },
          {
            id_trabajo_odon: "",
            trabajo: "",
            posicion: "izquierda",
            nombrePosicion: "Mesial",
            alcance: "cara",
          },
          {
            id_trabajo_odon: "",
            trabajo: "",
            posicion: "derecha",
            nombrePosicion: "Distal",
            alcance: "cara",
          },
          {
            id_trabajo_odon: "",
            trabajo: "",
            posicion: "arriba",
            nombrePosicion: "Lingual",
            alcance: "cara",
          },
          {
            id_trabajo_odon: "",
            trabajo: "",
            posicion: "abajo",
            nombrePosicion: "Vestibular",
            alcance: "cara",
          },
          {
            id_trabajo_odon: "",
            trabajo: "",
            posicion: "arriba-pieza",
            nombrePosicion: "Exterior-Arriba",
            alcance: "exterior-pieza",
          },
          {
            id_trabajo_odon: "",
            trabajo: "",
            posicion: "abajo-pieza",
            nombrePosicion: "Exterior-Abajo",
            alcance: "exterior-pieza",
          },
        ],
      },
      {
        id: 74,
        nombre: "Primer Premolar",
        posiciones: [
          {
            id_trabajo_odon: "",
            trabajo: "",
            posicion: "pieza",
            nombrePosicion: "Pieza",
            alcance: "pieza",
          },
          {
            id_trabajo_odon: "",
            trabajo: "",
            posicion: "centro",
            nombrePosicion: "Oclusal",
            alcance: "cara",
          },
          {
            id_trabajo_odon: "",
            trabajo: "",
            posicion: "izquierda",
            nombrePosicion: "Mesial",
            alcance: "cara",
          },
          {
            id_trabajo_odon: "",
            trabajo: "",
            posicion: "derecha",
            nombrePosicion: "Distal",
            alcance: "cara",
          },
          {
            id_trabajo_odon: "",
            trabajo: "",
            posicion: "arriba",
            nombrePosicion: "Lingual",
            alcance: "cara",
          },
          {
            id_trabajo_odon: "",
            trabajo: "",
            posicion: "abajo",
            nombrePosicion: "Vestibular",
            alcance: "cara",
          },
          {
            id_trabajo_odon: "",
            trabajo: "",
            posicion: "arriba-pieza",
            nombrePosicion: "Exterior-Arriba",
            alcance: "exterior-pieza",
          },
          {
            id_trabajo_odon: "",
            trabajo: "",
            posicion: "abajo-pieza",
            nombrePosicion: "Exterior-Abajo",
            alcance: "exterior-pieza",
          },
        ],
      },
      {
        id: 75,
        nombre: "Segundo Premolar",
        posiciones: [
          {
            id_trabajo_odon: "",
            trabajo: "",
            posicion: "pieza",
            nombrePosicion: "Pieza",
            alcance: "pieza",
          },
          {
            id_trabajo_odon: "",
            trabajo: "",
            posicion: "centro",
            nombrePosicion: "Oclusal",
            alcance: "cara",
          },
          {
            id_trabajo_odon: "",
            trabajo: "",
            posicion: "izquierda",
            nombrePosicion: "Mesial",
            alcance: "cara",
          },
          {
            id_trabajo_odon: "",
            trabajo: "",
            posicion: "derecha",
            nombrePosicion: "Distal",
            alcance: "cara",
          },
          {
            id_trabajo_odon: "",
            trabajo: "",
            posicion: "arriba",
            nombrePosicion: "Lingual",
            alcance: "cara",
          },
          {
            id_trabajo_odon: "",
            trabajo: "",
            posicion: "abajo",
            nombrePosicion: "Vestibular",
            alcance: "cara",
          },
          {
            id_trabajo_odon: "",
            trabajo: "",
            posicion: "arriba-pieza",
            nombrePosicion: "Exterior-Arriba",
            alcance: "exterior-pieza",
          },
          {
            id_trabajo_odon: "",
            trabajo: "",
            posicion: "abajo-pieza",
            nombrePosicion: "Exterior-Abajo",
            alcance: "exterior-pieza",
          },
        ],
      },
    ],
  },
];
