import React from "react";

import Draggable, { DraggableCore } from "react-draggable";
import Pieza from "./Pieza";

function Cuadrantes({ pacienteData, piezaSelector }) {
  switch (pacienteData.cuadrante) {
    case 1:
      return (
        <div className="flex items-start">
          {pacienteData.data.length > 0
            ? pacienteData.data.map((d, index) => (
                <Pieza
                  key={index}
                  posiciones={d.posiciones}
                  data={d}
                  piezaSelector={piezaSelector}
                />
              ))
            : null}
        </div>
      );
    case 2:
      return (
        <div className="flex items-start">
          {pacienteData.data.length > 0
            ? pacienteData.data.map((d, index) => (
                <Pieza
                  key={index}
                  posiciones={d.posiciones}
                  data={d}
                  piezaSelector={piezaSelector}
                />
              ))
            : null}
        </div>
      );
    case 3:
      return (
        <div className="flex items-start">
          {pacienteData.data.length > 0
            ? pacienteData.data.map((d, index) => (
                <Pieza
                  key={index}
                  posiciones={d.posiciones}
                  data={d}
                  piezaSelector={piezaSelector}
                />
              ))
            : null}
        </div>
      );
    case 4:
      return (
        <div className="flex items-start">
          {pacienteData.data.length > 0
            ? pacienteData.data.map((d, index) => (
                <Pieza
                  key={index}
                  posiciones={d.posiciones}
                  data={d}
                  piezaSelector={piezaSelector}
                />
              ))
            : null}
        </div>
      );
    default:
      return <div></div>;
  }
}

export default Cuadrantes;
