import React, { useContext } from "react";

function FechaHoy() {
  let month = [
    "Enero",
    "Febrero",
    "Marzo",
    "Abril",
    "Mayo",
    "Junio",
    "Julio",
    "Agosto",
    "Septiembre",
    "Octubre",
    "Noviembre",
    "Diciembre",
  ];
  let days = [
    "Domingo",
    "Lunes",
    "Martes",
    "Miércoles",
    "Jueves",
    "Viernes",
    "Sábado",
  ];

  let date = new Date();
  return (
    <div className="flex items-center">
      <svg className="h-6 w-6 mr-2 text-gray-400" viewBox="0 0 24 24">
        <path
          d="M12 19a1 1 0 1 0-1-1a1 1 0 0 0 1 1zm5 0a1 1 0 1 0-1-1a1 1 0 0 0 1 1zm0-4a1 1 0 1 0-1-1a1 1 0 0 0 1 1zm-5 0a1 1 0 1 0-1-1a1 1 0 0 0 1 1zm7-12h-1V2a1 1 0 0 0-2 0v1H8V2a1 1 0 0 0-2 0v1H5a3 3 0 0 0-3 3v14a3 3 0 0 0 3 3h14a3 3 0 0 0 3-3V6a3 3 0 0 0-3-3zm1 17a1 1 0 0 1-1 1H5a1 1 0 0 1-1-1v-9h16zm0-11H4V6a1 1 0 0 1 1-1h1v1a1 1 0 0 0 2 0V5h8v1a1 1 0 0 0 2 0V5h1a1 1 0 0 1 1 1zM7 15a1 1 0 1 0-1-1a1 1 0 0 0 1 1zm0 4a1 1 0 1 0-1-1a1 1 0 0 0 1 1z"
          fill="currentColor"
        ></path>
      </svg>
      <p className="text-gray-500 font-light">
        <span className="text-gray-600 font-bold">{days[date.getDay()]}</span>
        {" "}
        {date.getDate() +
          " de " +
          month[date.getMonth()] +
          ", " +
          date.getFullYear()}
      </p>
    </div>
  );
}

export default FechaHoy;
