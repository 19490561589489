export const GET_PACIENTE_DATA = 'GET_PACIENTE_DATA';
export const GET_PACIENTE_TEMPORALES_DATA = 'GET_PACIENTE_TEMPORALES_DATA';
export const DIENTE_SELECCIONADO = 'DIENTE_SELECCIONADO';
export const CARA_SELECCIONADA = 'CARA_SELECCIONADA';
export const SECTOR_MUTADO = 'SECTOR_MUTADO';

export const GET_TRABAJOS = 'GET_TRABAJOS';
export const FILTER_TRABAJOS = 'FILTER_TRABAJOS';

export const CAMBIAR_ESTADO = 'CAMBIAR_ESTADO';

export const SET_ALERT = "SET_ALERT";
export const DELETE_ALERT = "DELETE_ALERT";