import React, { useContext } from "react";
import DientesContext from "../../context/dientes/dientesContext";
import AuthContext from "../../context/auth/authContext";

function Caras({ cara, diente }) {
  const dientesContext = useContext(DientesContext);
  const { getSeleccionadoData, getCaraData, setModalOpen } = dientesContext;
  const authContext = useContext(AuthContext);
  const { canEdit } = authContext;

  //Me veo obligado a agregar este campo
  //ya que me resulta imposible identificar
  //a qué diente pertenece la cara sin él
  const dataCara = { ...cara, idDiente: diente.id };

  const handleClick = () => {
    getSeleccionadoData(diente);
    // getCaraData(cara);
    getCaraData(dataCara);
    setModalOpen(canEdit);
  };

  let color = "#ffffff";
  let colorSecundario = "#ffffff";
  let puntoCentral = false;

  if (cara.trabajo) {
    color = cara.trabajo.color;
    colorSecundario = cara.trabajo.colorSecundario;
    if (cara.trabajo.nombre === "recidiva") {
      puntoCentral = true;
    }
  }

  if (cara.posicion === "centro") {
    return (
      <div className="relative">
        <div className="flex items-center justify-center">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 198.02 198.02"
            fill={color}
            className="h-5 w-5"
            onClick={handleClick}
          >
            <rect
              stroke="gray"
              strokeWidth="20"
              x="0.5"
              y="0.5"
              width="197.02"
              height="197.02"
            />
          </svg>
          {puntoCentral ? (
            <svg
              viewBox="0 0 120 120"
              fill={colorSecundario}
              className="h-2 w-2 absolute"
              onClick={handleClick}
            >
              <circle cx="60" cy="60" r="50" />
            </svg>
          ) : null}
        </div>
      </div>
    );
  } else {
    return (
      <div>
        <div className="flex items-center justify-center">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 548.81 175.89"
            fill={color}
            onClick={handleClick}
          >
            <polygon
              stroke="gray"
              strokeWidth="20"
              points="0 0 175.89 175.89 372.92 175.89 548.81 0 0 0"
            />
          </svg>
          {puntoCentral ? (
            <svg
              viewBox="0 0 120 120"
              fill={colorSecundario}
              className="h-2 w-2 absolute"
              onClick={handleClick}
            >
              <circle cx="60" cy="60" r="50" />
            </svg>
          ) : null}
        </div>
      </div>
    );
  }
}

export default Caras;
